import { FC, useContext } from "react";
import {
  AdapterCreateServiceContext,
  AdapterCreateServiceContextProvider,
} from "../../contexts/services/adapter/adapter-create-service-context";
import CrudModal from "../CrudModal";

type AdapterCreateModalProps = {
  mode: string;
  isOpen: boolean;
  setOpen: Function;
  afterSave?: Function;
};
const AdapterCreateModal: FC<AdapterCreateModalProps> = ({
  mode,
  isOpen,
  setOpen,
  afterSave,
}) => {
  const contextAdapter = useContext(AdapterCreateServiceContext);

  return (
    <CrudModal
      template={[
        {
          name: "ชื่อค่ายเกม",
          field: "name",
          placeholder: "ชื่อค่ายเกม",
          value: contextAdapter.fields.name,
          type: "text",
        },
        {
          name: "รหัสค่ายเกม",
          field: "codename",
          placeholder: "รหัสค่ายเกม",
          value: contextAdapter.fields.codename,
          type: "text",
        },
        {
          name: "Link ค่ายเกม",
          field: "endpoint",
          placeholder: "Link ค่ายเกม",
          value: contextAdapter.fields.endpoint,
          type: "text",
        },
        {
          name: "ประเภทค่ายเกม",
          field: "type",
          placeholder: "ประเภทค่ายเกม",
          value: contextAdapter.fields.type,
          type: "text",
        },
        {
          name: "Link โลโก้เกม",
          field: "logo",
          placeholder: "Link",
          value: contextAdapter.fields.logo,
          type: "text",
        },
      ]}
      error={contextAdapter.error}
      isModalOpen={isOpen}
      onToggleModal={() => setOpen(!isOpen)}
      onUpdateField={(field: string, value: any) => {
        contextAdapter.setFields(field, value);
      }}
      onSubmit={
        mode === "info"
          ? () => {
              contextAdapter.saveFields(() => {
                setOpen(false);
                afterSave && afterSave();
              });
            }
          : undefined
      }
    />
  );
};

const AdapterCreateModalHoc = (props: AdapterCreateModalProps) => {
  return (
    <AdapterCreateServiceContextProvider>
      <AdapterCreateModal {...props} />
    </AdapterCreateServiceContextProvider>
  );
};
export default AdapterCreateModalHoc;
