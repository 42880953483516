import { FC, useContext, useEffect } from "react";
import {
  AdminEditServiceContext,
  AdminEditServiceContextProvider,
} from "../../contexts/services/admin/AdminEditServiceContext";
import CrudModal from "../CrudModal";

type AdminEditModalProps = {
  id: string;
  mode: string;
  isOpen: boolean;
  setOpen: Function;
  afterSave?: Function;
};
const AdminEditModal: FC<AdminEditModalProps> = ({
  id,
  mode,
  isOpen,
  setOpen,
  afterSave,
}) => {
  const contextAdmin = useContext(AdminEditServiceContext);
  useEffect(() => {
    if (id) {
      contextAdmin.setId(id);
      contextAdmin.fetch(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <CrudModal
      template={[
        {
          name: "ชื่อผู้ใช้",
          field: "username",
          placeholder: "ชื่อผู้ใช้",
          value: contextAdmin.data.username,
          type: "text",
          isDisable: true,
        },
        {
          name: "ประเภท",
          field: "type",
          placeholder: "ประเภท",
          value: contextAdmin.fields.type,
          type: "roleAdmin",
        },
      ]}
      error={contextAdmin.error}
      isModalOpen={isOpen}
      onToggleModal={() => setOpen(!isOpen)}
      onUpdateField={(field: string, value: any) => {
        contextAdmin.setFields(field, value);
      }}
      onSubmit={
        mode === "info"
          ? () => {
            contextAdmin.saveFields(() => {
              setOpen(false);
              afterSave && afterSave();
            });
          } : undefined
      }
    />
  );
};

const AdminEditModalHoc = (props: AdminEditModalProps) => {
  return (
    <AdminEditServiceContextProvider>
      <AdminEditModal {...props} />
    </AdminEditServiceContextProvider>
  );
};
export default AdminEditModalHoc;
