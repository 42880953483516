import { createContext, FC, useCallback, useState } from "react";
import useCallToService from "../../../hooks/useCallToService";
import { IAgentOperator } from "../../../models/entities/AgentOperator";

interface IAgentOperatorCreateServiceField extends Partial<IAgentOperator> {}

type TAgentOperatorCreateServiceProps = {
  id: string;
  fields: IAgentOperatorCreateServiceField;
  isProcessing: boolean;
  error: string;
  setId: Function;
  setFields: Function;
  saveFields: Function;
};

const defaultContext: TAgentOperatorCreateServiceProps = {
  id: "",
  fields: {
    username: "",
    password: "",
    type: "",
    locked: false,
  },
  isProcessing: false,
  error: "",
  setId: () => {},
  setFields: () => {},
  saveFields: () => {},
};
const createCreateServiceEndpoint = () =>
  `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/agent-operator/register`;
export const AgentOperatorCreateServiceContext = createContext(defaultContext);
export const AgentOperatorCreateServiceContextProvider: FC<any> = ({
  children,
}) => {
  const [id, setId] = useState(defaultContext.id);
  const [fields, setLocalFields] = useState(defaultContext.fields);
  const [isProcessing, setProcessing] = useState(false);
  const [error, setError] = useState("");
  const [callToService] = useCallToService();

  const saveFields = useCallback(
    async (callback: Function) => {
      setProcessing(true);
      try {
        const response = await callToService(
          createCreateServiceEndpoint(),
          "POST",
          fields
        );

        if (response.statusCode === 1000 && response.data) {
          callback(response.data);
        } else if (response.statusCode !== 1000) {
          setError(response.statusMessage);
        }
        setProcessing(false);
      } catch (error) {
        setError((error as Error).message);
        setProcessing(false);
      }
    },
    [callToService, fields]
  );

  const setFields = (field: string, value: any) => {
    setLocalFields({ ...fields, [field]: value });
  };

  return (
    <AgentOperatorCreateServiceContext.Provider
      value={{
        id,
        fields,
        isProcessing,
        error,
        setId,
        setFields,
        saveFields,
      }}>
      {children}
    </AgentOperatorCreateServiceContext.Provider>
  );
};
