import { FC, useContext, useEffect } from "react";
import {
  TransactionDetailServiceContext,
  TransactionDetailServiceContextProvider,
} from "../../../contexts/services/summary/TransactionDetailServiceContext";
import CrudModal from "../../CrudModal";

type SumTranDetailModalProps = {
  listId: string,
  id: string;
  mode: string;
  isOpen: boolean;
  setOpen: Function;
  afterSave?: Function;
};
const SumTranDetailModal: FC<SumTranDetailModalProps> = ({
  listId,
  id,
  mode,
  isOpen,
  setOpen,
  afterSave,
}) => {
  const contextSumTranDetail = useContext(TransactionDetailServiceContext);
  useEffect(() => {
    if (id && listId) {
      contextSumTranDetail.setId(id);
      contextSumTranDetail.setlistId(listId);
      contextSumTranDetail.fetch(id, listId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, listId]);

  return (
    <CrudModal
      template={[
        {
          name: "ชื่อรายการ",
          field: "user",
          placeholder: "ชื่อรายการ",
          value: contextSumTranDetail.data.user,
          type: "text",
          isDisable: true,
        },
        {
          name: "ชื่อผู้ใช้",
          field: "username",
          placeholder: "ชื่อผู้ใช้",
          value: contextSumTranDetail.data.username,
          type: "text",
          isDisable: true,
        },
        {
          name: "Bet Id",
          field: "betId",
          placeholder: "BetId",
          value: contextSumTranDetail.data.betId,
          type: "text",
          isDisable: true,
        },
        {
          name: "Bet Type",
          field: "betType",
          placeholder: "Bet Type",
          value: contextSumTranDetail.data.betType,
          type: "text",
          isDisable: true,
        },
        {
          name: "Bet Type From Game",
          field: "betTypeFromGame",
          placeholder: "Bet Type From Game",
          value: contextSumTranDetail.data.betTypeFromGame,
          type: "text",
          isDisable: true,
        },
        {
          name: "Game Brand",
          field: "gameBrand",
          placeholder: "Game Brand",
          value: contextSumTranDetail.data.gameBrand,
          type: "text",
          isDisable: true,
        },
        {
          name: "Game Code",
          field: "gameCode",
          placeholder: "Game Code",
          value: contextSumTranDetail.data.gameCode,
          type: "text",
          isDisable: true,
        },
      ]}
      error={contextSumTranDetail.error}
      isModalOpen={isOpen}
      onToggleModal={() => setOpen(!isOpen)}
    />
  );
};

const SumTranDetailModalHoc = (props: SumTranDetailModalProps) => {
  return (
    <TransactionDetailServiceContextProvider>
      <SumTranDetailModal {...props} />
    </TransactionDetailServiceContextProvider>
  );
};
export default SumTranDetailModalHoc;
