import React, { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";

const LoginModal = ({
  isModalOpen,
  onToggleModal,
  onAfterModalClose,
}: {
  isModalOpen: boolean;
  onToggleModal: () => void;
  onAfterModalClose: () => void;
}) => {
  const context = useContext(AuthContext);
  if (!isModalOpen) return <></>;
  return (
    isModalOpen && (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          zIndex: 99999,
        }}>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Prompt",
          }}>
          {/* <Modal
      isOpen={isModalOpen}
      onAfterOpen={onAfterModalClose}
      onRequestClose={onToggleModal}
      style={{}}
      contentLabel="Example Modal"> */}

          <div
            style={{
              position: "relative",
              zIndex: 2,
              color: "white",
              padding: 24,
              backgroundColor: "rgba(0,0,0,0.8)",
              border: "1px solid gold",
              borderRadius: 8,
            }}>
            <h2 style={{ marginBottom: 8 }}>เข้าสู่ระบบ</h2>
            <div
              style={{
                marginBottom: 8,
                color: context.error ? "red" : "white",
              }}>
              {context.error || "กรอกชื่อผู้ใช้และรหัสผ่าน"}
            </div>
            <div>
              <input
                value={context.username}
                style={{ padding: 8 }}
                placeholder="ชื่อผู้ใช้"
                onChange={(e) => context.setUsername(e.target.value)}
              />
            </div>
            <div>
              <input
                value={context.password}
                style={{ padding: 8 }}
                placeholder="รหัสผ่าน"
                type={"password"}
                onChange={(e) => context.setPassword(e.target.value)}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <button
                style={{
                  marginTop: 16,
                  padding: 8,
                  backgroundColor: "red",
                  color: "white",
                  border: "1px solid transparent",
                  borderRadius: 4,
                  cursor: "pointer",
                }}
                onClick={onToggleModal}>
                ปิด
              </button>
              <button
                style={{
                  marginTop: 16,
                  flex: 1,
                  padding: 8,
                  backgroundColor: "green",
                  color: "white",
                  border: "1px solid transparent",
                  borderRadius: 4,
                  cursor: "pointer",
                }}
                onClick={() =>
                  context.onAuthenticate && context.onAuthenticate()
                }>
                ล็อคอิน
              </button>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
              zIndex: 1,
            }}
            onClick={onToggleModal}></div>
        </div>
      </div>
    )
  );
};

export default LoginModal;
