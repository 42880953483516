import "./dashboard.css";
import NavigationPanel from "../components/NavigationPanel";
import DashboardStats from "../components/DashboardStats";

const DashboardScreen = () => {

  return (
    <NavigationPanel>
      <div className="relative pt-6 mt-4">
        <DashboardStats />
      </div>
    </NavigationPanel>
  );
};

export default DashboardScreen;
