import { FC, useCallback, useContext, useEffect, useState, useRef } from "react";
import CollectionFilter from "../components/collection/CollectionFilter";
import CollectionTable from "../components/collection/CollectionTable";
import AgentCreateModalHoc from "../components/modal/AgentCreateModal";
import AgentEditModalHoc from "../components/modal/AgentEditModal";
import AgentDeleteModalHoc from "../components/modal/AgentDeleteModal";
import NavigationPanel from "../components/NavigationPanel";
import { AgentDeleteServiceContextProvider } from "../contexts/services/agent/AgentDeleteServiceContext";
import {
  AgentListServiceContext,
  AgentListServiceContextProvider,
} from "../contexts/services/agent/AgentListServiceContext";
import SessionVerifier from "../contexts/SessionContext";


const AgentListScreen: FC<any> = () => {

  const contextAgents = useContext(AgentListServiceContext);
  const [isUserCreateModalOpen, setUserCreateModalOpen] = useState(false);

  const [editId, setEditId] = useState("");
  const [isUserEditModalOpen, setUserEditModalOpen] = useState(false);

  const [delId, setdelId] = useState("");
  const [isUserDeleteModalOpen, setUserDeleteModalOpen] = useState(false);

  const fetchCollection = useCallback(() => {
    contextAgents.onFetch();
  }, [contextAgents]);

  const shouldFetch = useRef(true);

  useEffect(() => {
    if (shouldFetch.current) {
      shouldFetch.current = false;
      fetchCollection();
    }
  });

  return (
    <NavigationPanel>
      <div className="relative pt-6 mt-4">
        <CollectionFilter
          template={[
            {
              name: "ชื่อผู้ใช้",
              field: "username",
              placeholder: "ชื่อผู้ใช้",
              value: contextAgents.query.username,
              type: "text",
            },
            {
              name: "รหัสย่อ",
              field: "prefix",
              placeholder: "รหัสย่อ",
              value: contextAgents.query.prefix,
              type: "text",
            },
            {
              name: "ตั้งแต่วันที่",
              field: "startDate",
              placeholder: "วันเริ่มต้นที่ต้องการค้นหา",
              value: contextAgents.query.startDate,
              type: "datetime",
            },
            {
              name: "ถึงวันที่",
              field: "endDate",
              placeholder: "วันสิ้นสุดที่ต้องการค้นหา",
              value: contextAgents.query.endDate,
              type: "datetime",
            },
          ]}
          onUpdateFilter={(field: any, value: any) =>
            contextAgents.setQuery(field, value)
          }
          onSearch={() => contextAgents.onFetch()}
        />
        <CollectionTable
          texts={{
            title: "รายชื่อ Agent",
            description: "รายชื่อเอเย่นท์",
            buttonTextAdd: "เพิ่ม Agent ใหม่",
            buttonTextEdit: "แก้ไข",
          }}
          template={[
            {
              name: "ชื่อผู้ใช้",
              field: "username",
              isLink: true,
              linkPath: "/transaction/{_id}",
              linkFieldReplace: "_id",
            },
            {
              name: "รหัสย่อ",
              field: "prefix",
            },
            {
              name: "รหัสระบบ",
              field: "code",
            },
            { name: "ประเภท", field: "agentType" },
            {
              name: "บัญชีถูกระงับ",
              type: "boolean",
              field: "locked",
              isEvent: true,
              eventCallback: (data: { _id: string }) => {
                setdelId(data._id);
                setUserDeleteModalOpen(true);
              },
            },
          ]}
          collection={contextAgents.collection.map((item: any) => ({
            ...item,
          }))}
          events={{
            onAdd: (data: { codename: string }) => {
              setUserCreateModalOpen(true);
            },
            onEdit: (data: { _id: string }) => {
              setEditId(data._id);
              setUserEditModalOpen(true);
            },
          }}
        />

        <AgentCreateModalHoc
          isOpen={isUserCreateModalOpen}
          setOpen={(status: boolean) => setUserCreateModalOpen(status)}
          afterSave={() => { fetchCollection() }}
          mode={"info"}
        />
        <AgentDeleteModalHoc
          id={delId}
          isOpen={isUserDeleteModalOpen}
          setOpen={(status: boolean) => setUserDeleteModalOpen(status)}
          afterSave={() => { fetchCollection() }}
          mode={"info"}
        />
        <AgentEditModalHoc
          id={editId}
          isOpen={isUserEditModalOpen}
          setOpen={(status: boolean) => setUserEditModalOpen(status)}
          afterSave={() => { fetchCollection() }}
          mode={"info"}
        />
      </div>
    </NavigationPanel>
  );
};

const AgentListScreenHoc = (props: any) => {
  return (
    <SessionVerifier>
      <AgentDeleteServiceContextProvider>
        <AgentListServiceContextProvider>
          <AgentListScreen {...props} />
        </AgentListServiceContextProvider>
      </AgentDeleteServiceContextProvider>
    </SessionVerifier>
  );
};
export default AgentListScreenHoc;
