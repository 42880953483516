import { FC, useContext } from "react";
import {
    UserCreateServiceContext,
    UserCreateServiceContextProvider,
} from "../../contexts/services/user/UserCreateServiceContext";
import CrudModal from "../CrudModal";

type UserCreateModalProps = {
    mode: string;
    isOpen: boolean;
    setOpen: Function;
    afterSave?: Function;
};
const UserCreateModal: FC<UserCreateModalProps> = ({
    mode,
    isOpen,
    setOpen,
    afterSave,
}) => {
    
    const contextUser = useContext(UserCreateServiceContext);

    return (
        <CrudModal
            template={[
                {
                    name: "ชื่อผู้ใช้",
                    field: "username",
                    placeholder: "ชื่อผู้ใช้",
                    value: contextUser.fields.username,
                    type: "text",
                    isDisable: false,
                },
                {
                    name: "สมัครวันที่",
                    field: "createdAt",
                    placeholder: "วันเริ่มต้นที่ต้องการค้นหา",
                    value: contextUser.fields.createdAt,
                    type: "datetime",
                    isDisable: true,
                },
                {
                    name: "เดิมพันสูงสุด",
                    field: "betLimit",
                    placeholder: "อัตตราเดิมพันสูงสุดของผู้เล่น",
                    value: contextUser.fields.betLimit,
                    type: "text",
                    isDisable: mode === "info" ? false : false,
                },
                {
                    name: "ห้ามการเดิมพัน",
                    field: "locked",
                    placeholder: "การอนุญาติเข้าเล่นเกมของผู้ใช้",
                    value: contextUser.fields.locked,
                    type: "boolean",
                    isDisable: mode === "info" ? false : false,
                },
            ]}
            error={contextUser.error}
            isModalOpen={isOpen}
            onToggleModal={() => setOpen(!isOpen)}
            onUpdateField={(field: string, value: any) => {
                contextUser.setFields(field, value);
            }}
            onSubmit={
                mode === "info" ?() => {
                    contextUser.saveFields(() => {
                      setOpen(false);
                      afterSave && afterSave();
                    });
                  }: undefined
              }
        />
    );
};

const UserCreateModalHoc = (props: UserCreateModalProps) => {
    return (
        <UserCreateServiceContextProvider>
            <UserCreateModal {...props} />
        </UserCreateServiceContextProvider>
    );
};
export default UserCreateModalHoc;
