import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LandingScreen from "./screens/Logins";
import { AuthContextProvider } from "./contexts/AuthContext";
import DashboardScreen from "./screens/Dashboards";
import UserListScreen from "./screens/UserList";
import SessionVerifier from "./contexts/SessionContext";
import AdapterListScreenHoc from "./screens/AdapterList";
import AgentListScreenHoc from "./screens/AgentList";
import AgentSharingScreenHoc from "./screens/AgentSharingList";
import AdminListScreen from "./screens/AdminList";
import TransactionScreen from "./screens/Transactions";
import ReportScreen from "./screens/Reports";
import SummaryScreen from "./screens/Summaries";
import SummaryAgentDailyHoc from "./screens/SummaryAgentDaily";
import SummaryAgentMonthlyScreenHoc from "./screens/SummaryAgentMonthly";
import SummaryGameDailyScreenHoc from "./screens/SummaryGameDaily";
import SummaryGameDailyByGameScreenHoc from "./screens/SummaryGameDailyByGame";
import SummaryGameMonthlyScreenHoc from "./screens/SummaryGameMonthly";
import SummaryGameMonthlyByGameScreenHoc from "./screens/SummaryGameMonthlyByGame";
import SummaryTransactionFromReportScreenHoc from "./screens/SummaryTransactionFromReport";
import AgentOperatorScreenHoc from "./screens/AgentOperatorList";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingScreen />,
  },
  {
    path: "/dashboard",
    element: (
      <SessionVerifier>
        <DashboardScreen />
      </SessionVerifier>
    ),
  },
  {
    path: "/admin/list",
    element: (
      <SessionVerifier>
        <AdminListScreen />
      </SessionVerifier>
    ),
  },
  {
    path: "/user/list",
    element: (
      <SessionVerifier>
        <UserListScreen />
      </SessionVerifier>
    ),
  },
  {
    path: "/adapter/list",
    element: <AdapterListScreenHoc />,
  },
  {
    path: "/agent/list",
    element: <AgentListScreenHoc />,
  },
  {
    path: "/agentoperator",
    element: <AgentOperatorScreenHoc />,
  },
  {
    path: "/agentsharing",
    element: <AgentSharingScreenHoc />,
  },
  {
    path: "/transaction/:id",
    element: <TransactionScreen />,
  },
  {
    path: "/report",
    element: <ReportScreen />,
  },
  {
    path: "/summary",
    element: <SummaryScreen />,
  },
  {
    path: "/summary/agentdaily",
    element: <SummaryAgentDailyHoc />,
  },
  {
    path: "/summary/agentmonthly",
    element: <SummaryAgentMonthlyScreenHoc />,
  },
  {
    path: "/summary/gamedaily",
    element: <SummaryGameDailyScreenHoc />,
  },
  {
    path: "/summary/gamedailybygame",
    element: <SummaryGameDailyByGameScreenHoc />,
  },
  {
    path: "/summary/gamemonthly",
    element: <SummaryGameMonthlyScreenHoc />,
  },
  {
    path: "/summary/gamemonthlybygame",
    element: <SummaryGameMonthlyByGameScreenHoc />,
  },
  {
    path: "/summary/tsreport",
    element: <SummaryTransactionFromReportScreenHoc />,
  },
]);

function Router() {
  return (
    <div
      className="App"
      style={{ width: "100%", height: "100%", backgroundColor: "white" }}
    >
      <AuthContextProvider>
        <RouterProvider router={router} />
      </AuthContextProvider>
    </div>
  );
}

export default Router;
