import { FC } from "react";
import { get } from "lodash";

import FormSwitch from "../form/FormSwitch";
import noData from "../../assets/nodata.jpg"

type CollectionTableProps = {
  texts: {
    title: string;
    description: string;
    buttonTextAdd: string;
    buttonTextEdit: string;
    buttonTextDelete: string;
  };
  template: {
    name: string;
    field: string;
    type?: string;
    isLink: boolean;
    linkPath: string;
    linkFieldReplace: string;
    isEvent: boolean;
    eventCallback: Function;
  }[];
  events: { onAdd: Function; onEdit: Function; onDelete: Function };
  collection: any[];
};
const CollectionTable: FC<any> = (props: CollectionTableProps) => {
  const { texts, events, template, collection } = props;
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">{texts.title}</h1>
          <p className="mt-2 text-sm text-gray-700">{texts.description}</p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          {events && events.onAdd && (
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-sky-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              onClick={() => events.onAdd()}>
              {texts.buttonTextAdd}
            </button>
          )}
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      #
                    </th>
                    {template.map((item) => (
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        {item.name}
                      </th>
                    ))}
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only"></span>
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only"></span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {collection.map((data, index) => (
                    <tr key={index}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {index + 1}
                      </td>
                      {template.map((item) => (
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {item.isLink && item.linkPath && (
                            <a
                              href={`${item.linkPath.replace(
                                "{" + item.linkFieldReplace + "}",
                                data[item.linkFieldReplace]
                              )}`}
                              className="hover:text-indigo-500 hover:underline">
                              {get(data, item.field)}
                            </a>
                          )
                          }
                          {item.isEvent && item.eventCallback && (
                            <div
                              className="hover:text-indigo-500 hover:underline cursor-pointer"
                              onClick={() => item.eventCallback(data)}>
                              {get(data, item.field)}
                            </div>
                          )
                          }
                          {item.isEvent && item.eventCallback && item.type === "boolean" && (
                            <FormSwitch
                              enabled={get(data, item.field)}
                              setEnabled={() => { item.eventCallback(data) }}
                            />
                          )
                          }
                          {!item.isLink &&
                            !item.isEvent &&
                            !item.type &&
                            get(data, item.field)}
                          {!item.isLink &&
                            !item.isEvent &&
                            item.type === "boolean" && (
                              <FormSwitch
                                enabled={get(data, item.field)}
                                setEnabled={() => { }}
                              />
                            )}
                        </td>
                      ))}
                      {events && events.onEdit && (
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <div
                            className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                            onClick={() => events.onEdit(data)}>
                            {texts.buttonTextEdit}

                            <span className="sr-only">
                              , {texts.buttonTextEdit}
                            </span>
                          </div>
                        </td>
                      )}
                      {events && events.onDelete && (
                        <button
                          type="button"
                          className="inline-flex items-center justify-center mt-3 ml-5 rounded-md border border-transparent bg-red-500 px-1 py-1 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                          onClick={() => events.onDelete(data)}>
                          {texts.buttonTextDelete}
                          <span className="sr-only">
                            , {texts.buttonTextEdit}
                          </span>
                        </button>

                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
              {collection.length === 0 && (
                <div className="flex justify-center">
                  <div>
                    <img src={noData} alt="" className="items-center justify-center" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollectionTable;
