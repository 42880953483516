import { createContext, FC, useCallback, useState } from "react";
import useCallToService from "../../../hooks/useCallToService";
import { IAdapter } from "../../../models/entities/Adapter";

type AdapterEditServiceField = {
  name: string;
  codename: string;
  endpoint: string;
  type: string;
  logo: string;
};

type AdapterEditServiceProps = {
  id: string;
  data: IAdapter;
  fields: AdapterEditServiceField;
  isProcessing: boolean;
  error: string;
  setId: Function;
  setFields: Function;
  saveFields: Function;
  fetch: Function;
};

const defaultContext: AdapterEditServiceProps = {
  id: "",
  data: {
    name: "",
    codename: "",
    endpoint: "",
    type: "",
    logo: "",
    isDisable: false,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  fields: {
    name: "",
    codename: "",
    endpoint: "",
    type: "",
    logo: "",
  },
  isProcessing: false,
  error: "",
  setId: () => {},
  setFields: () => {},
  saveFields: () => {},
  fetch: () => {},
};
const createGetServiceEndpoint = (id: string, manualId?: string) =>
  `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/adapter/get/${
    manualId || id
  }`;
const createUpdateServiceEndpoint = (id: string, manualId?: string) =>
  `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/adapter/update/${
    manualId || id
  }`;
export const AdapterEditServiceContext = createContext(defaultContext);
export const AdapterEditServiceContextProvider: FC<any> = ({ children }) => {
  const [id, setId] = useState(defaultContext.id);
  const [data, setData] = useState<IAdapter>(defaultContext.data);
  const [fields, setLocalFields] = useState(defaultContext.fields);
  const [isProcessing, setProcessing] = useState(false);
  const [error, setError] = useState("");
  const [callToService] = useCallToService();
  const fetch = useCallback(
    async (manualId?: string) => {
      setError("");
      setProcessing(true);
      try {
        const response = await callToService(
          createGetServiceEndpoint(id, manualId),
          "POST",
          {}
        );

        if (response.data) {
          setData(response.data);
          setLocalFields({
            name: response.data.name,
            codename: response.data.codename,
            endpoint: response.data.endpoint,
            type: response.data.type,
            logo: response.data.logo,
          });
        } else if (response.statusCode !== 1000) {
          setError(response.statusMessage);
        }
        setProcessing(false);
      } catch (error) {
        setError((error as Error).message);
        setProcessing(false);
      }
    },
    [callToService, id]
  );

  const saveFields = useCallback(
    async (callback: Function) => {
      setProcessing(true);
      try {
        const response = await callToService(
          createUpdateServiceEndpoint(id),
          "PUT",
          fields
        );

        if (response.data) {
          setData(response.data);
          setLocalFields({
            name: response.data.name,
            codename: response.data.codename,
            endpoint: response.data.endpoint,
            type: response.data.type,
            logo: response.data.logo,
          });
          callback(response.data);
        } else if (response.statusCode !== 1000) {
          setError(response.statusMessage);
        }
        setProcessing(false);
      } catch (error) {
        setError((error as Error).message);
        setProcessing(false);
      }
    },
    [callToService, fields, id]
  );

  const setFields = (field: string, value: any) => {
    setLocalFields({ ...fields, [field]: value });
  };

  return (
    <AdapterEditServiceContext.Provider
      value={{
        id,
        data,
        fields,
        isProcessing,
        error,
        setId,
        setFields,
        saveFields,
        fetch,
      }}
    >
      {children}
    </AdapterEditServiceContext.Provider>
  );
};
