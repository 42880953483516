import { createContext, FC, useCallback, useState } from "react";
import { setQueryFunction } from "../../StandardContextUtils";
import useCallToService from "../../../hooks/useCallToService";

type AgentOperatorListQuery = {
  agent: string;
  username: string;
  page: number;
  limit: number;
};

type AgentOperatorListServiceContextProps = {
  query: AgentOperatorListQuery;
  collection: any[];
  setQuery: (field: string, value: any) => void;
  onFetch: (token?: string) => void;
};

const defaultContext: AgentOperatorListServiceContextProps = {
  query: {
    agent: "",
    username: "",
    page: 1,
    limit: 50,
  },
  collection: [],
  setQuery: (field: string, value: any) => { },
  onFetch: (token?: string) => { },
};

const getServiceEndpoint = () =>
  `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/agent-operator/list`;
export const AgentOperatorListServiceContext = createContext(defaultContext);
export const AgentOperatorListServiceContextProvider: FC<any> = ({ children }) => {
  const [collection, setCollection] = useState([]);
  const [query, setLocalQuery] = useState(defaultContext.query);
  const [callToService] = useCallToService();

  const onFetch = useCallback(async () => {
    try {
      const response = await callToService(getServiceEndpoint(), "POST", query);
      if (response.data) setCollection(response.data);
    } catch (error) {
    }
  }, [callToService, query]);
  const setQuery = setQueryFunction(query, setLocalQuery);

  return (
    <AgentOperatorListServiceContext.Provider
      value={{
        query,
        collection,
        setQuery,
        onFetch,
      }}>
      {children}
    </AgentOperatorListServiceContext.Provider>
  );
};
