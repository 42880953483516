import { createContext, FC, useCallback, useState } from "react";
import useCallToService from "../../../hooks/useCallToService";
import { IAdmin } from "../../../models/entities/Admin";

type AdminEditServiceField = {
  type: string;
};

type AdminEditServiceProps = {
  id: string;
  data: IAdmin;
  fields: AdminEditServiceField;
  isProcessing: boolean;
  error: string;
  setId: Function;
  setFields: Function;
  saveFields: Function;
  fetch: Function;
};

const defaultContext: AdminEditServiceProps = {
  id: "",
  data: {
    username: "",
    password: "",
    type: "",
    locked: false,
    activeLoginIP: "",
    createdBy: "",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  fields: {
    type: "",
  },
  isProcessing: false,
  error: "",
  setId: () => {},
  setFields: () => {},
  saveFields: () => {},
  fetch: () => {},
};
const createGetServiceEndpoint = (id: string, manualId?: string) =>
  `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/admin/get/${manualId || id}`;
const createUpdateServiceEndpoint = (id: string, manualId?: string) =>
  `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/admin/update/${
    manualId || id
  }`;
export const AdminEditServiceContext = createContext(defaultContext);
export const AdminEditServiceContextProvider: FC<any> = ({ children }) => {
  const [id, setId] = useState(defaultContext.id);
  const [data, setData] = useState<IAdmin>(defaultContext.data);
  const [fields, setLocalFields] = useState(defaultContext.fields);
  const [isProcessing, setProcessing] = useState(false);
  const [error, setError] = useState("");
  const [callToService] = useCallToService();
  const fetch = useCallback(
    async (manualId?: string) => {
      setError("");
      setProcessing(true);
      try {
        const response = await callToService(
          createGetServiceEndpoint(id, manualId),
          "POST",
          {}
        );

        if (response.data) {
          setData(response.data);
          setLocalFields({
            type: response.data.type,
          });
        } else if (response.statusCode !== 1000) {
          setError(response.statusMessage);
        }
        setProcessing(false);
      } catch (error) {
        setError((error as Error).message);
        setProcessing(false);
      }
    },
    [callToService, id]
  );

  const saveFields = useCallback(
    async (callback: Function) => {
      setProcessing(true);
      try {
        const response = await callToService(
          createUpdateServiceEndpoint(id),
          "PUT",
          fields,
        );

        if (response.data) {
          setData(response.data);
          setLocalFields({
            type: response.data.type,
          });
          
          callback(response.data);
        } else if (response.statusCode !== 1000) {
          setError(response.statusMessage);
        }
        setProcessing(false);
      } catch (error) {
        setError((error as Error).message);
        setProcessing(false);
      }
    },
    [callToService, fields, id]
  );

  const setFields = (field: string, value: any) => {
    setLocalFields({ ...fields, [field]: value });
  };

  return (
    <AdminEditServiceContext.Provider
      value={{
        id,
        data,
        fields,
        isProcessing,
        error,
        setId,
        setFields,
        saveFields,
        fetch,
      }}>
      {children}
    </AdminEditServiceContext.Provider>
  );
};
