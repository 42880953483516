import { createContext, FC, useCallback, useState } from "react";
import useCallToService from "../../../hooks/useCallToService";
import { setQueryFunction } from "../../StandardContextUtils";

type GameDailyReportByGameQuery = {
  day: string;
  month: string;
  year: string;
};

type GameDailyReportByGameServiceContextProps = {
  query: GameDailyReportByGameQuery;
  collection: any[];
  setQuery: (field: string, value: any) => void;
  onFetch: (token?: string) => void;
};

const defaultContext: GameDailyReportByGameServiceContextProps = {
  query: {
    day: "",
    month: "",
    year: "",
  },
  collection: [],
  setQuery: (field: string, value: any) => {},
  onFetch: (token?: string) => {},
};
const getServiceEndpoint = () =>
  `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/summary/:agentId/game/daily/:gameBrand`;
export const GameDailyReportByGameServiceContext = createContext(defaultContext);
export const GameDailyReportByGameServiceContextProvider: FC<any> = ({ children }) => {
  const [collection, setCollection] = useState([]);
  const [query, setLocalQuery] = useState(defaultContext.query);
  const [callToService] = useCallToService();
  
  const onFetch = useCallback(async () => {
    try {
      const response = await callToService(getServiceEndpoint(), "POST", query);
      if (response.data) setCollection(response.data);
    } catch (error) {
    }
  }, [callToService, query]);
  const setQuery = setQueryFunction(query, setLocalQuery);

  return (
    <GameDailyReportByGameServiceContext.Provider
      value={{
        query,
        collection,
        setQuery,
        onFetch,
      }}>
      {children}
    </GameDailyReportByGameServiceContext.Provider>
  );
};
