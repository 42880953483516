import { createContext, FC, useCallback, useState } from "react";
import useCallToService from "../../../hooks/useCallToService";

type UserDeleteServiceProps = {
  id: string;
  isProcessing: boolean;
  error: string;
  setId: Function;
  softDelete: Function;
};

const defaultContext: UserDeleteServiceProps = {
  id: "",
  isProcessing: false,
  error: "",
  setId: () => {},
  softDelete: () => {},
};
const createDeleteServiceEndpoint = (id: string, manualId?: string) =>
  `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/user/delete/${
    manualId || id
  }`;

export const UserDeleteServiceContext = createContext(defaultContext);
export const UserDeleteServiceContextProvider: FC<any> = ({ children }) => {
  const [id, setId] = useState(defaultContext.id);
  const [isProcessing, setProcessing] = useState(false);
  const [error, setError] = useState("");
  const [callToService] = useCallToService();
  const softDelete = useCallback(
    async (manualId: string, callback: Function) => {
      setProcessing(true);
      try {
        const response = await callToService(
          createDeleteServiceEndpoint(id, manualId),
          "POST",
          {}
        );

        if (response.data) {
          callback(response.data);
        } else if (response.statusCode !== 1000) {
          setError(response.statusMessage);
        }
        setProcessing(false);
      } catch (error) {
        setError((error as Error).message);
        setProcessing(false);
      }
    },
    [callToService, id]
  );

  return (
    <UserDeleteServiceContext.Provider
      value={{
        id,
        isProcessing,
        error,
        setId,
        softDelete,
      }}>
      {children}
    </UserDeleteServiceContext.Provider>
  );
};
