import { createContext, FC, useCallback, useState } from "react";
import useCallToService from "../../../hooks/useCallToService";
import { setQueryFunction } from "../../StandardContextUtils";

type AdapterListQuery = {
  page: number;
  limit: number;
};
type AgentListServiceContextProps = {
  query: AdapterListQuery;
  collection: any[];
  setQuery: (field: string, value: any) => void;
  onFetch: (token?: string) => void;
};
const defaultContext: AgentListServiceContextProps = {
  query: {
    page: 1,
    limit: 50,
  },
  collection: [],
  setQuery: (field: string, value: any) => { },
  onFetch: (token?: string) => { },
};
const getServiceEndpoint = () =>
  `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/adapter/list`;
export const AdapterListServiceContext = createContext(defaultContext);
export const AdapterListServiceContextProvider: FC<any> = ({ children }) => {
  const [collection, setCollection] = useState([]);
  const [query, setLocalQuery] = useState(defaultContext.query);
  const [callToService] = useCallToService();
  const onFetch = useCallback(async () => {
    try {
      const response = await callToService(getServiceEndpoint(), "POST", query);
      if (response.data) setCollection(response.data);
    } catch (error) {
    }
  }, [callToService, query]);

  const setQuery = setQueryFunction(query, setLocalQuery);

  return (
    <AdapterListServiceContext.Provider
      value={{
        query,
        collection,
        setQuery,
        onFetch,
      }}>
      {children}
    </AdapterListServiceContext.Provider>
  );
};
