import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import CollectionTable from "../components/collection/CollectionTable";
import NavigationPanel from "../components/NavigationPanel";
import {
  AdapterListServiceContext,
  AdapterListServiceContextProvider,
} from "../contexts/services/adapter/adapter-list-service-context";
import SessionVerifier from "../contexts/SessionContext";
import AdapterCreateModalHoc from "../components/modal/AdapterCreateModal";
import AdapterEditModalHoc from "../components/modal/AdapterEditModal";
import AdapterDeleteModalHoc from "../components/modal/AdapterDeleteModal";
import { AdapterDeleteServiceContextProvider } from "../contexts/services/adapter/adapter-delete-service-context";

// import AdapterEditModalHoc from "../components/modal/AdapterEditModal";

const AdapterListScreen: FC<any> = () => {
  const contextAdapters = useContext(AdapterListServiceContext);
  const [isAdapterCreateModalOpen, setAdapterCreateModalOpen] = useState(false);

  const [isAdapterEditModalOpen, setAdapterEditModalOpen] = useState(false);
  const [editId, setEditId] = useState("");

  const [delId, setdelId] = useState("");
  const [isAdapterDeleteModalOpen, setAdapterDeleteModalOpen] = useState(false);

  const fetchCollection = useCallback(() => {
    contextAdapters.onFetch();
  }, [contextAdapters]);

  const shouldFetch = useRef(true);

  useEffect(() => {
    if (shouldFetch.current) {
      shouldFetch.current = false;
      fetchCollection();
    }
  });

  return (
    <NavigationPanel>
      <div className="relative pt-6 mt-4">
        <CollectionTable
          texts={{
            title: "ค่ายเกม",
            description: "รายชื่อรายการค่ายเกมทั้งหมดของเอเย่นท์",
            buttonTextEdit: "ตรวจรายการเฉพาะค่าย",
            buttonTextAdd: "เพิ่มเกม",
          }}
          template={[
            {
              name: "ชื่อค่าย",
              field: "name",
              isEvent: true,
              eventCallback: (data: { _id: string }) => {
                setEditId(data._id);
                setAdapterEditModalOpen(true);
              },
            },
            {
              name: "รหัสค่าย",
              field: "codename",
            },
            { name: "ประเภทค่าย", field: "type" },
            {
              name: "ลิ้งค่ายเกม",
              field: "endpoint",
            },
            {
              name: "ลิ้งค์โลโก้",
              field: "logo",
            },
            {
              name: "บัญชีถูกระงับ",
              type: "boolean",
              field: "isDisable",
              isEvent: true,
              eventCallback: (data: { _id: string }) => {
                setdelId(data._id);
                setAdapterDeleteModalOpen(true);
              },
            },
          ]}
          collection={contextAdapters.collection.map((item: any) => ({
            ...item,
          }))}
          events={{
            onEdit: (data: { _id: string }) => {
              setEditId(data._id);
              setAdapterEditModalOpen(true);
            },
            onAdd: (data: { codename: string }) => {
              setAdapterCreateModalOpen(true);
            },
          }}
        />

        <AdapterEditModalHoc
          id={editId}
          isOpen={isAdapterEditModalOpen}
          setOpen={(status: boolean) => setAdapterEditModalOpen(status)}
          afterSave={() => {
            fetchCollection();
          }}
          mode={"info"}
        />
        <AdapterDeleteModalHoc
          id={delId}
          isOpen={isAdapterDeleteModalOpen}
          setOpen={(status: boolean) => setAdapterDeleteModalOpen(status)}
          afterSave={() => {
            fetchCollection();
          }}
          mode={"info"}
        />
        <AdapterCreateModalHoc
          isOpen={isAdapterCreateModalOpen}
          setOpen={(status: boolean) => setAdapterCreateModalOpen(status)}
          afterSave={() => {
            fetchCollection();
          }}
          mode={"info"}
        />
      </div>
    </NavigationPanel>
  );
};

const AdapterListScreenHoc = (props: any) => {
  return (
    <SessionVerifier>
      <AdapterDeleteServiceContextProvider>
        <AdapterListServiceContextProvider>
          <AdapterListScreen {...props} />
        </AdapterListServiceContextProvider>
      </AdapterDeleteServiceContextProvider>
    </SessionVerifier>
  );
};
export default AdapterListScreenHoc;
