import { useContext, useEffect } from "react";
import { SelfContext } from "../contexts/SelfContext";

const UserHeader = () => {
  const contextHeader = useContext(SelfContext);
  useEffect(() => {
    // contextHeader.onSyncSelf();
    const processingSync = () => contextHeader.onSyncSelf();
    window.addEventListener("focus", () => processingSync);
    return () => {
      window.removeEventListener("focus", () => processingSync);
    };
  }, [contextHeader]);

  useEffect(() => {
    contextHeader.onSyncSelf();
  }, [contextHeader]);

  if (!contextHeader.user) return <></>;
  return (
    <>
      <div className="pl-4 pr-4">{contextHeader.user.username}</div>
    </>
  );
};

export default UserHeader;
