import { FC, useContext } from "react";
import {
  AgentCreateServiceContext,
  AgentCreateServiceContextProvider,
} from "../../contexts/services/agent/AgentCreateServiceContext";
import CrudModal from "../CrudModal";

type AgentCreateModalProps = {
  mode: string;
  isOpen: boolean;
  setOpen: Function;
  afterSave?: Function;
};
const AgentCreateModal: FC<AgentCreateModalProps> = ({
  mode,
  isOpen,
  setOpen,
  afterSave,
}) => {
  const contextAgent = useContext(AgentCreateServiceContext);

  return (
    <CrudModal
      template={[
        {
          name: "ชื่อผู้ใช้",
          field: "username",
          placeholder: "ชื่อผู้ใช้",
          value: contextAgent.fields.username,
          type: "text",
        },
        {
          name: "รหัสผ่าน",
          field: "password",
          placeholder: "รหัสผ่าน",
          value: contextAgent.fields.password,
          type: "password",
        },
        {
          name: "URL ระบบหลังบ้าน",
          field: "endpoint",
          placeholder: "URL ระบบหลังบ้าน",
          value: contextAgent.fields.endpoint,
          type: "text",
        },
        {
          name: "เกมที่เปิดให้เล่น",
          field: "games",
          placeholder: "เกมที่เปิดให้เล่น",
          value: contextAgent.fields.games,
          type: "text",
        },
        {
          name: "รหัสย่อ",
          field: "prefix",
          placeholder: "รหัสย่อ",
          value: contextAgent.fields.prefix,
          type: "text",
        },
        {
          name: "รหัสเทียบ",
          field: "code",
          placeholder: "รหัสเทียบ",
          value: contextAgent.fields.code,
          type: "text",
        },
        {
          name: "ประเภท",
          field: "agentType",
          placeholder: "ประเภท",
          value: contextAgent.fields.agentType,
          type: "roleAgent",
        },
      ]}
      error={contextAgent.error}
      isModalOpen={isOpen}
      onToggleModal={(field: string, value: any) => {
        setOpen(!isOpen)
        contextAgent.setFields(field, value);
      }}
      onUpdateField={(field: string, value: any) => {
        contextAgent.setFields(field, value);
      }}
      onSubmit={
        mode === "info" ? () => {
          contextAgent.saveFields(() => {
            setOpen(false);
            afterSave && afterSave();
          });
        } : undefined
      }
    />
  );
};

const AgentCreateModalHoc = (props: AgentCreateModalProps) => {
  return (
    <AgentCreateServiceContextProvider>
      <AgentCreateModal {...props} />
    </AgentCreateServiceContextProvider>
  );
};
export default AgentCreateModalHoc;
