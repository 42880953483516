import { FC, useEffect, useRef } from "react";
import FormList from "../form/FormList";

type CollectionPaginationProps = {
  page: number;
  limit: number;
  size: number;
  onFetch: Function;
  onPageChange: Function;
  onLimitChange: Function;
};

const CollectionPagination: FC<any> = (props: CollectionPaginationProps) => {
  const { page, limit, size, onFetch, onPageChange, onLimitChange } = props;
  const shouldFetch = useRef(false);

  useEffect(() => {
    if (shouldFetch.current) {
      shouldFetch.current = true;
      onFetch();
    }
    shouldFetch.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page]);
  return (
    <div className="px-4 sm:px-6 lg:px-8 mb-4">
      <FormList
        title="จำนวนผลลัพท์"
        selected={limit.toString()}
        collection={["10", "50", "100"]}
        onSelect={onLimitChange}
      />
      {page > 1 && (
        <button
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto mt-4 ml-2"
          onClick={() => onPageChange(page - 1)}>
          ก่อนหน้า
        </button>
      )}
      <button
        type="button"
        className="inline-flex items-center justify-center rounded-md border border-transparent shadow-md bg-white px-4 py-2 text-sm font-medium text-black sm:w-auto mt-4 mx-4">
        {page}
      </button>
      {size > 0 && size === limit && (
        <button
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto mt-4"
          onClick={() => onPageChange(page + 1)}>
          ถัดไป
        </button>
      )}
    </div>
  );
};

export default CollectionPagination;
