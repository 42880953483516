import { FC, useContext } from "react";
import CollectionFilter from "../components/collection/CollectionFilter";
import CollectionTable from "../components/collection/CollectionTable";
import NavigationPanel from "../components/NavigationPanel";
import {
  GameDailyReportServiceContext,
  GameDailyReportServiceContextProvider,
} from "../contexts/services/summary/GameDailyReportServiceContext";
import SessionVerifier from "../contexts/SessionContext";
import SummaryDashboard from "../components/SummaryDashboard";

const SummaryGameDailyScreen: FC<any> = () => {
  const contextGameDailyReport = useContext(GameDailyReportServiceContext);

  return (
    <NavigationPanel>
      <div className="relative pt-6 mt-4">
        <CollectionFilter
          template={[
            {
              name: "รหัส Agent",
              field: "agentID",
              placeholder: "รหัส Agent",
              value: contextGameDailyReport.id,
              type: "textID",
            },
            {
              name: "วัน",
              field: "day",
              placeholder: "วันที่ต้องการค้นหา",
              value: contextGameDailyReport.query.day,
              type: "text",
            },
            {
              name: "เดือน",
              field: "month",
              placeholder: "เดือนที่ต้องการค้นหา",
              value: contextGameDailyReport.query.month,
              type: "text",
            },
            {
              name: "ปี",
              field: "year",
              placeholder: "ปีที่ต้องการค้นหา",
              value: contextGameDailyReport.query.year,
              type: "text",
            },
          ]}
          onUpdateFilter={(field: any, value: any) =>
            contextGameDailyReport.setQuery(field, value)
          }
          onUpdateID={(searchID: any) => {
            contextGameDailyReport.setId(searchID);
          }}
          onSearch={() => contextGameDailyReport.onFetch()}
        />
        <SummaryDashboard />
        <CollectionTable
          texts={{
            title: "Game Daily",
            description: "Game Daily",
            buttonTextEdit: "ตรวจสอบข้อมูล",
          }}
          template={[
            { name: "วัน", field: "day" },
            { name: "เดือน", field: "month" },
            { name: "ปี", field: "year" },
          ]}
          collection={contextGameDailyReport.collection.map((item: any) => ({
            ...item,
          }))}
        />
      </div>
    </NavigationPanel>
  );
};

const SummaryGameDailyScreenHoc = (props: any) => {
  return (
    <SessionVerifier>
      <GameDailyReportServiceContextProvider>
        <SummaryGameDailyScreen {...props} />
      </GameDailyReportServiceContextProvider>
    </SessionVerifier>
  );
};
export default SummaryGameDailyScreenHoc;
