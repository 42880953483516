import { FC, useContext, useEffect } from "react";
import {
  AgentEditServiceContext,
  AgentEditServiceContextProvider,
} from "../../contexts/services/agent/AgentEditServiceContext";
import CrudModal from "../CrudModal";

type AgentEditModalProps = {
  id: string;
  mode: string;
  isOpen: boolean;
  setOpen: Function;
  afterSave?: Function;
};
const AgentEditModal: FC<AgentEditModalProps> = ({
  id,
  mode,
  isOpen,
  setOpen,
  afterSave,
}) => {
  const contextAgent = useContext(AgentEditServiceContext);
  useEffect(() => {
    if (id) {
      contextAgent.setId(id);
      contextAgent.fetch(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <CrudModal
      template={[
        {
          name: "ชื่อผู้ใช้",
          field: "username",
          placeholder: "ชื่อผู้ใช้",
          value: contextAgent.data.username,
          type: "text",
          isDisable: true,
        },
        {
          name: "เกมที่เปิดให้เล่น",
          field: "games",
          placeholder: "เกมที่เปิดให้เล่น",
          value: contextAgent.fields.games,
          type: "text",
        },
        {
          name: "URL ระบบหลังบ้าน",
          field: "endpoint",
          placeholder: "URL ระบบหลังบ้าน",
          value: contextAgent.fields.endpoint,
          type: "text",
        },
        {
          name: "รหัสย่อ",
          field: "prefix",
          placeholder: "รหัสย่อ",
          value: contextAgent.data.prefix,
          type: "text",
          isDisable: true,
        },
        {
          name: "รหัสเทียบ",
          field: "code",
          placeholder: "รหัสเทียบ",
          value: contextAgent.data.code,
          type: "text",
          isDisable: true,
        },
        {
          name: "ประเภท",
          field: "agentType",
          placeholder: "ประเภท",
          value: contextAgent.fields.agentType,
          type: "roleAgent",
        },
        {
          name: "รหัส API Key",
          field: "apiKey",
          placeholder: "รหัส API Key",
          value: contextAgent.data.apiKey,
          type: "text",
          isDisable: true,
        },
        {
          name: "รหัส Secret",
          field: "secret",
          placeholder: "รหัส Secret",
          value: contextAgent.data.secret,
          type: "text",
          isDisable: true,
        },
      ]}
      error={contextAgent.error}
      isModalOpen={isOpen}
      onToggleModal={() => setOpen(!isOpen)}
      onUpdateField={(field: string, value: any) => {
        contextAgent.setFields(field, value);
      }}
      onSubmit={
        mode === "info"
          ? () => {
            contextAgent.saveFields(() => {
              setOpen(false);
              afterSave && afterSave();
            });
          } : undefined
      }
    />
  );
};

const AgentEditModalHoc = (props: AgentEditModalProps) => {
  return (
    <AgentEditServiceContextProvider>
      <AgentEditModal {...props} />
    </AgentEditServiceContextProvider>
  );
};
export default AgentEditModalHoc;
