import { FC, useContext } from "react";
import {
  AgentSharingCreateServiceContext,
  AgentSharingCreateServiceContextProvider,
} from "../../contexts/services/agentSharing/AgentSharingCreateServiceContext";
import CrudModal from "../CrudModal";

type AgentSharingCreateModalProps = {
  mode: string;
  isOpen: boolean;
  setOpen: Function;
  afterSave?: Function;
};
const AgentSharingCreateModal: FC<AgentSharingCreateModalProps> = ({
  mode,
  isOpen,
  setOpen,
  afterSave,
}) => {
  const contextAgentSharing = useContext(AgentSharingCreateServiceContext);

  return (
    <CrudModal
      template={[
        {
          name: "Agent",
          field: "agent",
          placeholder: "Agent",
          value: contextAgentSharing.fields.agent,
          type: "text",
        },
        {
          name: "Parent Agent",
          field: "parentAgent",
          placeholder: "632c08dd14f1957f8c3500c0",
          value: contextAgentSharing.fields.parentAgent,
          type: "sharingAgentCreate",
          isDisable: false,
        },
        {
          name: "Agent Percentage",
          field: "parentPercentage",
          placeholder: "",
          value: contextAgentSharing.fields.parentPercentage,
          type: "text",
        },
      ]}
      error={contextAgentSharing.error}
      isModalOpen={isOpen}
      onToggleModal={() => setOpen(!isOpen)}
      onUpdateField={(field: string, value: any) => {
        contextAgentSharing.setFields(field, value);
      }}
      onSubmit={
        mode === "info" ? () => {
          contextAgentSharing.saveFields(() => {
            setOpen(false);
            afterSave && afterSave();
          });
        } : undefined
      }
    />
  );
};

const AgentSharingCreateModalHoc = (props: AgentSharingCreateModalProps) => {
  return (
    <AgentSharingCreateServiceContextProvider>
      <AgentSharingCreateModal {...props} />
    </AgentSharingCreateServiceContextProvider>
  );
};
export default AgentSharingCreateModalHoc;
