import { FC, useContext, useEffect } from "react";
import {
  TransactionEditServiceContext,
  TransactionEditServiceContextProvider,
} from "../../contexts/services/transaction/TransactionDetailServiceContext";
import CrudModal from "../CrudModal";

type TransactionEditModalProps = {
  listId: string,
  agentId: string;
  mode: string;
  isOpen: boolean;
  setOpen: Function;
  afterSave?: Function;
};
const TransactionEditModal: FC<TransactionEditModalProps> = ({
  listId,
  agentId,
  mode,
  isOpen,
  setOpen,
  afterSave,
}) => {
  const contextTransaction = useContext(TransactionEditServiceContext);
  useEffect(() => {
    if (agentId && listId) {
      contextTransaction.setAgentId(listId);
      contextTransaction.setlistId(agentId);
      contextTransaction.fetch(agentId, listId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentId, listId]);

  return (
    <CrudModal
      template={[
        {
          name: "ชื่อรายการ",
          field: "user",
          placeholder: "ชื่อรายการ",
          value: contextTransaction.data.user,
          type: "text",
          isDisable: true,
        },
        {
          name: "ชื่อผู้ใช้",
          field: "username",
          placeholder: "ชื่อผู้ใช้",
          value: contextTransaction.data.username,
          type: "text",
          isDisable: true,
        },
        {
          name: "Bet Id",
          field: "betId",
          placeholder: "BetId",
          value: contextTransaction.data.betId,
          type: "text",
          isDisable: true,
        },
        {
          name: "Bet Type",
          field: "betType",
          placeholder: "Bet Type",
          value: contextTransaction.data.betType,
          type: "text",
          isDisable: true,
        },
        {
          name: "Bet Type From Game",
          field: "betTypeFromGame",
          placeholder: "Bet Type From Game",
          value: contextTransaction.data.betTypeFromGame,
          type: "text",
          isDisable: true,
        },
        {
          name: "Game Brand",
          field: "gameBrand",
          placeholder: "Game Brand",
          value: contextTransaction.data.gameBrand,
          type: "text",
          isDisable: true,
        },
        {
          name: "Game Code",
          field: "gameCode",
          placeholder: "Game Code",
          value: contextTransaction.data.gameCode,
          type: "text",
          isDisable: true,
        },
      ]}
      error={contextTransaction.error}
      isModalOpen={isOpen}
      onToggleModal={() => setOpen(!isOpen)}
    />
  );
};

const TransactionEditModalHoc = (props: TransactionEditModalProps) => {
  return (
    <TransactionEditServiceContextProvider>
      <TransactionEditModal {...props} />
    </TransactionEditServiceContextProvider>
  );
};
export default TransactionEditModalHoc;
