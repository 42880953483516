import { FC, useContext } from "react";
import CollectionFilter from "../components/collection/CollectionFilter";
import CollectionTable from "../components/collection/CollectionTable";
import NavigationPanel from "../components/NavigationPanel";
import {
  ReportServiceContext,
  ReportServiceContextProvider,
} from "../contexts/services/report/ReportServiceContext";
import SessionVerifier from "../contexts/SessionContext";

const ReportScreen: FC<any> = () => {
  const contextReport = useContext(ReportServiceContext);

  return (
    <NavigationPanel>
      <div className="relative pt-6 mt-4">
        <CollectionFilter
          template={[
            {
              name: "ชื่อผู้ใช้",
              field: "id",
              placeholder: "ชื่อผู้ใช้",
              value: contextReport.id,
              type: "textID",
            },
            {
              name: "วันที่",
              field: "date",
              placeholder: "วันที่ต้องการค้นหา",
              value: contextReport.query.date,
              type: "datetime",
            },
          ]}
          onUpdateFilter={(field: any, value: any) =>
            contextReport.setQuery(field, value)
          }
          onUpdateID={(searchID: any) => {
            contextReport.setId(searchID);
          }}
          onSearch={() => contextReport.onFetch()}
        />
        <CollectionTable
          texts={{
            title: "รายงานผล",
            description: "รายงานผล",
          }}
          template={[
            { name: "วันที่", field: "date" },
          ]}
          collection={contextReport.collection.map((item: any) => ({
            ...item,
          }))}
          events={{
          }}
        />
      </div>
    </NavigationPanel>
  );
};

const ReportListScreen = (props: any) => {
  return (
    <SessionVerifier>
      <ReportServiceContextProvider>
        <ReportScreen {...props} />
      </ReportServiceContextProvider>
    </SessionVerifier>
  );
};
export default ReportListScreen;
