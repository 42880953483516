import { FC } from "react";
import NavigationPanel from "../components/NavigationPanel";
import SessionVerifier from "../contexts/SessionContext";
import SummaryDashboard from "../components/SummaryDashboard";

const SummaryScreen: FC<any> = () => {
  return (
    <NavigationPanel>
      <div className="relative pt-6 mt-4">
        <SummaryDashboard />
      </div>
    </NavigationPanel>
  );
};

const SummaryScreenHoc = (props: any) => {
  return (
    <SessionVerifier>
      <SummaryScreen {...props} />
    </SessionVerifier>
  );
};
export default SummaryScreenHoc;
