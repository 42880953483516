import { FC, useContext, useEffect } from "react";
import {
  AdapterDeleteServiceContext,
  AdapterDeleteServiceContextProvider,
} from "../../contexts/services/adapter/adapter-delete-service-context";
import CrudModalDelete from "../CrudModalDelete";

type AdapterDeleteModalProps = {
  id: string;
  mode: string;
  isOpen: boolean;
  setOpen: Function;
  afterSave?: Function;
};
const AdapterDeleteModal: FC<AdapterDeleteModalProps> = ({
  id,
  mode,
  isOpen,
  setOpen,
  afterSave,
}) => {
  const contextAdapter = useContext(AdapterDeleteServiceContext);
  useEffect(() => {
    if (id) {
      contextAdapter.setId(id);
      contextAdapter.fetch(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <CrudModalDelete
      error={contextAdapter.error}
      isModalOpen={isOpen}
      onToggleModal={() => setOpen(!isOpen)}
      onSubmit={
        mode === "info"
          ? () => {
              contextAdapter.softDelete(() => {
                setOpen(false);
                afterSave && afterSave();
              });
            }
          : undefined
      }
    />
  );
};

const AdapterDeleteModalHoc = (props: AdapterDeleteModalProps) => {
  return (
    <AdapterDeleteServiceContextProvider>
      <AdapterDeleteModal {...props} />
    </AdapterDeleteServiceContextProvider>
  );
};
export default AdapterDeleteModalHoc;
