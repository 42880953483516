import React, { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import LoginModal from "../components/LoginModal";
import CollectionFilter from "../components/collection/CollectionFilter";
import CollectionTable from "../components/collection/CollectionTable";
import AdminCreateModalHoc from "../components/modal/AdminCreateModal";
import AdminEditModalHoc from "../components/modal/AdminEditModal";
import NavigationPanel from "../components/NavigationPanel";
import {
  AdminListServiceContext,
  AdminListServiceContextProvider,
} from "../contexts/services/admin/AdminListServiceContext";
import SessionVerifier from "../contexts/SessionContext";

const AdminListScreen: FC<any> = () => {

  const contextAgents = useContext(AdminListServiceContext);
  const [isUserCreateModalOpen, setUserCreateModalOpen] = useState(false);

  const [editId, setEditId] = useState("");
  const [isUserEditModalOpen, setUserEditModalOpen] = useState(false);

  const fetchCollection = useCallback(() => {
    contextAgents.onFetch();
  }, [contextAgents]);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const onToggleModal = useCallback(() => {
    setIsOpen(!modalIsOpen);
  }, [modalIsOpen]);

  const shouldFetch = useRef(true);

  useEffect(() => {
    if (shouldFetch.current) {
      shouldFetch.current = false;
      fetchCollection();
    }
  });

  return (
    <NavigationPanel>
      <div className="relative pt-6 mt-4">
        <CollectionFilter
          template={[
            {
              name: "ชื่อผู้ใช้",
              field: "username",
              placeholder: "ชื่อผู้ใช้",
              value: contextAgents.query.username,
              type: "text",
            },
            {
              name: "ตั้งแต่วันที่",
              field: "startDate",
              placeholder: "วันเริ่มต้นที่ต้องการค้นหา",
              value: contextAgents.query.startDate,
              type: "datetime",
            },
            {
              name: "ถึงวันที่",
              field: "endDate",
              placeholder: "วันสิ้นสุดที่ต้องการค้นหา",
              value: contextAgents.query.endDate,
              type: "datetime",
            },
          ]}
          onUpdateFilter={(field: any, value: any) =>
            contextAgents.setQuery(field, value)
          }
          onSearch={() => contextAgents.onFetch()}
        />
        <CollectionTable
          texts={{
            title: "รายชื่อ Admin",
            description: "รายชื่อแอดมิน",
            buttonTextAdd: "เพิ่ม Admin",
            buttonTextEdit: "แก้ไข",
          }}
          template={[
            {
              name: "ชื่อผู้ใช้",
              field: "username",
              isLink: false,
              linkPath: "/admin/{_id}",
              linkFieldReplace: "_id",
            },
            { name: "ประเภท", field: "type" },
          ]}
          collection={contextAgents.collection.map((item: any) => ({
            ...item,
          }))}
          events={{
            onAdd: (data: { codename: string }) => {
              setUserCreateModalOpen(true);
            },
            onEdit: (data: { _id: string }) => {
              setEditId(data._id);
              setUserEditModalOpen(true);
            },
          }}
        />
        <AdminCreateModalHoc
          isOpen={isUserCreateModalOpen}
          setOpen={(status: boolean) => setUserCreateModalOpen(status)}
          afterSave={() => { fetchCollection() }}
          mode={"info"}
        />
        <AdminEditModalHoc
          id={editId}
          isOpen={isUserEditModalOpen}
          setOpen={(status: boolean) => setUserEditModalOpen(status)}
          afterSave={() => { fetchCollection() }}
          mode={"info"}
        />
        <LoginModal
          isModalOpen={modalIsOpen}
          onAfterModalClose={() => { }}
          onToggleModal={onToggleModal}
        />
      </div>
    </NavigationPanel>
  );
};

const AdminListScreenHoc = (props: any) => {
  return (
    <SessionVerifier>
      <AdminListServiceContextProvider>
        <AdminListScreen {...props} />
      </AdminListServiceContextProvider>
    </SessionVerifier>
  );
};
export default AdminListScreenHoc;
