import { createContext, FC, useCallback, useState } from "react";
import useCallToService from "../../../hooks/useCallToService";

type TransactionEditQuery = {
  user: string;
  username: string;
  betId: string;
  betType: string;
  betTypeFromGame: string;
  gameBrand: string;
  gameCode: string;
  startDate: string;
  endDate: string;
  page: number;
  limit: number;
};

type TransactionEditServiceContextProps = {
  agentId: string,
  listId: string,
  data: TransactionEditQuery;
  setAgentId: (searchID: any) => void;
  setlistId: Function;
  isProcessing: boolean;
  error: string;
  fetch: Function;
};

const defaultContext: TransactionEditServiceContextProps = {
  agentId: "",
  listId: "",
  data: {
    user: "",
    username: "",
    betId: "",
    betType: "",
    betTypeFromGame: "",
    gameBrand: "",
    gameCode: "",
    startDate: "",
    endDate: "",
    page: 1,
    limit: 1,
  },
  setAgentId: (searchID: any) => { },
  setlistId: () => { },
  isProcessing: false,
  error: "",
  fetch: () => { },
};
const getServiceEndpoint = (listId?: string, agentId?: string) =>
  `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/transaction/${agentId}/get/${listId}`;
export const TransactionEditServiceContext = createContext(defaultContext);
export const TransactionEditServiceContextProvider: FC<any> = ({ children }) => {
  const [agentId, setAgentId] = useState(defaultContext.agentId);
  const [listId, setlistId] = useState(defaultContext.listId);
  const [data, setData] = useState(defaultContext.data);
  const [isProcessing, setProcessing] = useState(false);
  const [error, setError] = useState("");
  const [callToService] = useCallToService();

  const fetch = useCallback(
    async (agentId: string, listId: string) => {
      setError("");
      setProcessing(true);
      try {
        const response = await callToService(
          getServiceEndpoint(listId, agentId),
          "POST",
          {}
        );
        if (response.data) {
          setData(response.data);
        } else if (response.statusCode !== 1000) {
          setError(response.statusMessage);
        }
        setProcessing(false);
      } catch (error) {
        setError((error as Error).message);
        setProcessing(false);
      }
    },
    [callToService]
  );
  return (
    <TransactionEditServiceContext.Provider
      value={{
        agentId,
        listId,
        data,
        setAgentId,
        setlistId,
        isProcessing,
        error,
        fetch,
      }}>
      {children}
    </TransactionEditServiceContext.Provider>
  );
};
