import { FC, useContext, useEffect } from "react";
import {
  AgentSharingDeleteServiceContext
  , AgentSharingDeleteServiceContextProvider
} from "../../contexts/services/agentSharing/AgentSharingDeleteServiceContext";
import CrudModalDelete from "../CrudModalDelete";

type AgentSharingDeleteModalProps = {
  id: string;
  mode: string;
  isOpen: boolean;
  setOpen: Function;
  afterSave?: Function;
};
const AgentSharingDeleteModal: FC<AgentSharingDeleteModalProps> = ({
  id,
  mode,
  isOpen,
  setOpen,
  afterSave,
}) => {
  const contextAgentSharing = useContext(AgentSharingDeleteServiceContext);
  useEffect(() => {
    if (id) {
      contextAgentSharing.setId(id);
      contextAgentSharing.fetch(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <CrudModalDelete
      error={contextAgentSharing.error}
      isModalOpen={isOpen}
      onToggleModal={() => setOpen(!isOpen)}
      onSubmit={
        mode === "info" ? () => {
          contextAgentSharing.softDelete(() => {
            setOpen(false);
            afterSave && afterSave();
          });
        } : undefined
      }
    />
  );
};

const AgentSharingDeleteModalHoc = (props: AgentSharingDeleteModalProps) => {
  return (
    <AgentSharingDeleteServiceContextProvider>
      <AgentSharingDeleteModal {...props} />
    </AgentSharingDeleteServiceContextProvider>
  );
};
export default AgentSharingDeleteModalHoc;
