import { FC } from "react";
import DatePicker from "react-datepicker";
import Modal from "react-modal";
import ReactJson from "react-json-view";
import FormSwitch from "./form/FormSwitch";

type CrudModalProps = {
  template: {
    name: string;
    placeholder: string;
    field: string;
    type: string;
    value: any;
    valueCollection?: any;
    isDisable?: boolean;
  }[];
  error?: string;
  isModalOpen: boolean;
  onUpdateField?: Function;
  onToggleModal?: Function;
  onAfterModalClose?: Function;
  onSubmit?: Function;
};

const CrudModal: FC<CrudModalProps> = ({
  template,
  error,
  isModalOpen,
  onUpdateField,
  onToggleModal,
  onAfterModalClose,
  onSubmit,
}) => {

  if (!isModalOpen) return <></>;

  return (
    <Modal
      isOpen={isModalOpen}
      onAfterOpen={() => { }}
      onRequestClose={() => onToggleModal && onToggleModal()}
      className="absolute bg-white p-8 max-sm:w-full sm:left-1/2 sm:-translate-x-1/2 rounded-md border-solid border-gray-300 border-2 overflow-auto flex flex-col"
      style={{
        content: {
          marginTop: 64,
          inset: undefined,
          maxHeight: "calc(80%)",
        },
      }}
      contentLabel="Example Modal">
      <div className="flex flex-row flex-wrap overflow-auto">
        {template.map((item) => (
          <div className="pr-2 mb-3 sm:w-auto w-full ">
            <label className="block text-sm font-medium text-gray-700">
              {item.name}
            </label>
            <div className="mt-1 relative">
              {item.type === "text" && (
                <input
                  type={item.type}
                  name={item.field}
                  id={item.field}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm pr-6"
                  placeholder={item.placeholder}
                  value={item.value}
                  disabled={item.isDisable}
                  onChange={(e) =>
                    onUpdateField && onUpdateField(item.field, e.target.value)
                  }
                />
              )}
              {item.type === "password" && (
                <input
                  type={"password"}
                  name={item.field}
                  id={item.field}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm pr-6"
                  placeholder={item.placeholder}
                  value={item.value}
                  disabled={item.isDisable}
                  onChange={(e) =>
                    onUpdateField && onUpdateField(item.field, e.target.value)
                  }
                />
              )}
              {item.type === "roleAgent" && (
                <div className="flex flex-row justify-between">
                  <select
                    className="cursor-pointer bg-gray-50 border border-gray-300 text-gray-900  focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm pr-6 sm:w-auto w-full rounded-lg"
                    onChange={(e) => {
                      onUpdateField && onUpdateField(item.field, e.target.value);
                    }}>
                    <option
                      className="ring-indigo-500 border-indigo-500 ">เลือกประเภท Agent</option>
                    <option value="SENIOR"
                      className="ring-indigo-500 border-indigo-500"
                    >SENIOR</option>
                    <option value="MASTER"
                      className="ring-indigo-500 border-indigo-500"
                    >MASTER</option>
                    <option value="AGENT"
                      className="ring-indigo-500 border-indigo-500"
                    >AGENT</option>
                    <option value="SUBAGENT"
                      className="ring-indigo-500 border-indigo-500"
                    >SUBAGENT</option>
                  </select>
                </div>
              )}
              {item.type === "roleAdmin" && (
                <div className="flex flex-row justify-between">
                  <select
                    className="cursor-pointer bg-gray-50 border border-gray-300 text-gray-900  focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm pr-6 sm:w-auto w-full rounded-lg"
                    onChange={(e) => {
                      onUpdateField && onUpdateField(item.field, e.target.value);
                    }}>
                    <option
                      className="ring-indigo-500 border-indigo-500 ">เลือกประเภท Admin</option>
                    <option value="SUPERADMIN"
                      className="ring-indigo-500 border-indigo-500"
                    >SUPERADMIN</option>
                    <option value="ADMIN"
                      className="ring-indigo-500 border-indigo-500"
                    >ADMIN</option>
                    <option value="OPERATOR"
                      className="ring-indigo-500 border-indigo-500"
                    >OPERATOR</option>
                    <option value="REPORTER"
                      className="ring-indigo-500 border-indigo-500"
                    >REPORTER</option>
                  </select>
                </div>
              )}
              {item.type === "roleOperator" && (
                <div className="flex flex-row justify-between">
                  <select
                    className="cursor-pointer bg-gray-50 border border-gray-300 text-gray-900  focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm pr-6 sm:w-auto w-full rounded-lg"
                    onChange={(e) => {
                      onUpdateField && onUpdateField(item.field, e.target.value);
                    }}>
                    <option
                      className="ring-indigo-500 border-indigo-500 ">เลือกประเภท Operator</option>
                    <option value="SUPERMANAGER"
                      className="ring-indigo-500 border-indigo-500"
                    >SUPER MANAGER</option>
                    <option value="MANAGER"
                      className="ring-indigo-500 border-indigo-500"
                    >MANAGER</option>
                    <option value="OPERATOR"
                      className="ring-indigo-500 border-indigo-500"
                    >OPERATOR</option>
                    <option value="REPORTER"
                      className="ring-indigo-500 border-indigo-500"
                    >REPORTER</option>
                  </select>
                </div>
              )}
              {item.type === "sharingAgentCreate" && (
                <div className="flex flex-row justify-between">
                  <select
                    className="cursor-pointer bg-gray-50 border border-gray-300 text-gray-900  focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm pr-6 sm:w-auto w-full rounded-lg"
                    onChange={(e) => {
                      onUpdateField && onUpdateField(item.field, e.target.value);
                    }}>
                    <option
                      className="ring-indigo-500 border-indigo-500 ">เลือก Agent สายงาน</option>
                    <option value="632c08dd14f1957f8c3500c0"
                      className="ring-indigo-500 border-indigo-500"
                    >Agent01</option>
                    <option value="63d7a3ee9a7dd3ad2ea71e4f"
                      className="ring-indigo-500 border-indigo-500"
                    >Agent02</option>
                    
                  </select>
                </div>
              )}
              {item.type === "datetime" && (
                <DatePicker
                  className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm pr-6 sm:w-auto w-full "
                  placeholderText={item.placeholder}
                  selected={new Date(item.value)}
                  onChange={(e) => {
                    onUpdateField && onUpdateField(item.field, e);
                  }}
                  disabled={item.isDisable}
                  timeInputLabel="Time:"
                  dateFormat="dd/MM/yyyy HH:mm:ss"
                  showTimeInput
                />
              )}
              {item.type === "boolean" && (
                <FormSwitch
                  enabled={item.value}
                  setEnabled={
                    item.isDisable
                      ? () => { }
                      : (e: boolean) =>
                        onUpdateField && onUpdateField(item.field, e)
                  }
                />
              )}
              {item.type === "json" && <ReactJson src={item.value} />}
              {item.value &&
                !item.isDisable &&
                (item.type === "text" ||
                  item.type === "password" ||
                  item.type === "datetime") && (
                  <div
                    className="absolute cursor-pointer focus:border-indigo-500 focus:ring-indigo-500"
                    style={{ right: 1, top: 6 }}
                    onClick={(e) => {
                      onUpdateField && onUpdateField(item.field, "");
                    }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 ">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>
                )}
            </div>
          </div>
        ))}
      </div>
      {error && (
        <div className="flex flex-row rounded-md justify-between px-4 text-white pt-2 pb-2 shadow-md bg-red-400">
          {error}
        </div>
      )}
      <div className="flex flex-row justify-between">
        <button
          type="button"
          className="items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto w-full mt-4"
          onClick={() => onToggleModal && onToggleModal()}>
          ปิด
        </button>

        <button
          type="button"
          className="items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto w-full mt-4"
          onClick={() => onSubmit && onSubmit()}>
          บันทึก
        </button>
      </div>
    </Modal>
  );
};

export default CrudModal;
