import { FC, useContext, useState, useEffect, useCallback, useRef } from "react";
import { useParams } from "react-router-dom";
import CollectionTable from "../components/collection/CollectionTable";
import CollectionPagination from "../components/collection/CollectionPagination";
import TransactionEditModalHoc from "../components/modal/TransactionDetailModal";
import NavigationPanel from "../components/NavigationPanel";
import { AgentDeleteServiceContextProvider } from "../contexts/services/agent/AgentDeleteServiceContext";
import {
  TransactionListServiceContext,
  TransactionListServiceContextProvider,
} from "../contexts/services/transaction/TransactionListServiceContext";
import SessionVerifier from "../contexts/SessionContext";

const TransactionListScreen: FC<any> = () => {
  const contextTransaction = useContext(TransactionListServiceContext);

  const [agentId, setAgentId] = useState("");
  const [listId, setListId] = useState("");
  const [isTransactionEditModalOpen, setTransactionEditModalOpen] = useState(false);

  const getLinkID = useParams();

  const fetchCollection = useCallback(() => {
    contextTransaction.setId(getLinkID.id);
    contextTransaction.onFetch(getLinkID.id);
  }, [contextTransaction,getLinkID]);

  const shouldFetch = useRef(true);

  useEffect(() => {
    if (shouldFetch.current) {
      shouldFetch.current = false;
      fetchCollection();
    }
  });

  return (
    <NavigationPanel>
      <div className="relative pt-6 mt-4">
      <CollectionPagination
          page={contextTransaction.query.page}
          limit={contextTransaction.query.limit}
          size={contextTransaction.collection.length}
          onFetch={() => contextTransaction.onFetch()}
          onPageChange={(page: any) => {
            contextTransaction.setQuery("page", Number(page));
          }}
          onLimitChange={(limit: any) => {
            contextTransaction.setQuery("limit", Number(limit));
          }}
        />
        <CollectionTable
          texts={{
            title: "Transaction",
            description: "",
            buttonTextEdit: "ตรวจสอบรายการ",
          }}
          template={[
            {
              name: "User",
              field: "user",
              isEvent: true,
              eventCallback: (data: { _id: string, agent: string }) => {
                setListId(data._id);
                setAgentId(data.agent);
                setTransactionEditModalOpen(true);
              },
            },
            { name: "Username", field: "username" },
            { name: "Bet ID", field: "betId" },
            { name: "Bet Type", field: "betType" },
            { name: "ประเภทการ Bet จากเกมส์", field: "betTypeFromGame" },
            { name: "แบรนด์เกมส์", field: "gameBrand" },
            { name: "รหัสเกมส์", field: "gameCode" },
          ]}
          collection={contextTransaction.collection.map((item: any) => ({
            ...item,
          }))}
          events={{
            onEdit: (data: { _id: string, agent: string }) => {
              setAgentId(data.agent);
              setListId(data._id);
              setTransactionEditModalOpen(true);
            },
          }}
        />
        <TransactionEditModalHoc
          agentId={agentId}
          listId={listId}
          isOpen={isTransactionEditModalOpen}
          setOpen={(status: boolean) => setTransactionEditModalOpen(status)}
          afterSave={() => { }}
          mode={"info"}
        />
      </div>
    </NavigationPanel>
  );
};

const TransactionScreen = (props: any) => {
  return (
    <SessionVerifier>
      <AgentDeleteServiceContextProvider>
        <TransactionListServiceContextProvider>
          <TransactionListScreen {...props} />
        </TransactionListServiceContextProvider>
      </AgentDeleteServiceContextProvider>
    </SessionVerifier>
  );
};
export default TransactionScreen;
