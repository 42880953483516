import { createContext, FC, useCallback, useState } from "react";
import useCallToService from "../../../hooks/useCallToService";

type SumTransactionDetailQuery = {
  user: string;
  username: string;
  betId: string;
  betType: string;
  betTypeFromGame: string;
  gameBrand: string;
  gameCode: string;
  startDate: string;
  endDate: string;
  page: number;
  limit: number;
};

type SumTransactionDetailServiceContextProps = {
  id: string;
  listId: string;
  data: SumTransactionDetailQuery;
  setId: (searchID: any) => void;
  setlistId: Function;
  isProcessing: boolean;
  error: string;
  fetch: Function;
};

const defaultContext: SumTransactionDetailServiceContextProps = {
  id: "",
  listId: "",
  data: {
    user: "",
    username: "",
    betId: "",
    betType: "",
    betTypeFromGame: "",
    gameBrand: "",
    gameCode: "",
    startDate: "",
    endDate: "",
    page: 1,
    limit: 1,
  },
  setId: (searchID: any) => {},
  setlistId: () => {},
  isProcessing: false,
  error: "",
  fetch: () => {},
};
const getServiceEndpoint = (listId?: string, id?: string) =>
  `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/summary/${id}/get/${listId}`;
export const TransactionDetailServiceContext = createContext(defaultContext);
export const TransactionDetailServiceContextProvider: FC<any> = ({
  children,
}) => {
  const [id, setId] = useState(defaultContext.id);
  const [listId, setlistId] = useState(defaultContext.id);
  const [data, setData] = useState(defaultContext.data);
  const [isProcessing, setProcessing] = useState(false);
  const [error, setError] = useState("");
  const [callToService] = useCallToService();

  const fetch = useCallback(
    async (id: string, listId: string) => {
      setError("");
      setProcessing(true);
      try {
        const response = await callToService(
          getServiceEndpoint(id, listId),
          "POST",
          {}
        );
        if (response.data) {
          setData(response.data);
        } else if (response.statusCode !== 1000) {
          setError(response.statusMessage);
        }
        setProcessing(false);
      } catch (error) {
        setError((error as Error).message);
        setProcessing(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[callToService, id, listId]
  );

  return (
    <TransactionDetailServiceContext.Provider
      value={{
        id,
        listId,
        data,
        setId,
        setlistId,
        isProcessing,
        error,
        fetch,
      }}
    >
      {children}
    </TransactionDetailServiceContext.Provider>
  );
};
