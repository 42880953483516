import { createContext, FC, useCallback, useState } from "react";
import useCallToService from "../../../hooks/useCallToService";
import { IAgentSharing } from "../../../models/entities/AgentSharing";

type IAgentSharingDeleteServiceField = {
  agent: string,
  parentAgent: string,
  isDisable: boolean,
};

type AgentSharingDeleteServiceProps = {
  id: string;
  data: IAgentSharing;
  fields: IAgentSharingDeleteServiceField;
  isProcessing: boolean;
  error: string;
  setId: Function;
  setFields: Function;
  softDelete: Function;
  fetch: Function;
};

const defaultContext: AgentSharingDeleteServiceProps = {
  id: "",
  data: {
    agent: "",
    parentAgent: "",
    parentPercentage: 0,
    isDisable: false,
  },
  fields: {
    agent: "",
    parentAgent: "",
    isDisable: false,
  },
  isProcessing: false,
  error: "",
  setFields: () => { },
  setId: () => { },
  fetch: () => { },
  softDelete: () => { },
};
const createGetServiceEndpoint = (id: string, manualId?: string) =>
  `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/agent-sharing/get/${manualId || id}`;
const createDeleteServiceEndpoint = (id: string, manualId?: string) =>
  `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/agent-sharing/delete/${manualId || id
  }`;
const createRevertServiceEndpoint = (id: string, manualId?: string) =>
  `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/agent-sharing/revert/${manualId || id
  }`;
export const AgentSharingDeleteServiceContext = createContext(defaultContext);
export const AgentSharingDeleteServiceContextProvider: FC<any> = ({
  children,
}) => {
  const [id, setId] = useState(defaultContext.id);
  const [data, setData] = useState<IAgentSharing>(defaultContext.data);
  const [fields, setLocalFields] = useState(defaultContext.fields);
  const [isProcessing, setProcessing] = useState(false);
  const [error, setError] = useState("");
  const [callToService] = useCallToService();

  const fetch = useCallback(
    async (manualId?: string) => {
      setError("");
      setProcessing(true);
      try {
        const response = await callToService(
          createGetServiceEndpoint(id, manualId),
          "POST",
          {}
        );

        if (response.data) {
          setData(response.data);
          setLocalFields({
            agent: response.data.agent,
            parentAgent: response.data.parentAgent,
            isDisable: response.data.isDisable,
          });
        } else if (response.statusCode !== 1000) {
          setError(response.statusMessage);
        }
        setProcessing(false);
      } catch (error) {
        setError((error as Error).message);
        setProcessing(false);
      }
    },
    [callToService, id]
  );

  const softDelete = useCallback(
    async (callback: Function, manualId?: string) => {
      setProcessing(true);
      try {
        if (fields.isDisable === false) {
          const response = await callToService(
            createDeleteServiceEndpoint(id, manualId),
            "DELETE",
            fields,
          );
          if (response.data) {
            setData(response.data);
            setLocalFields({
              agent: response.data.agent,
              parentAgent: response.data.parentAgent,
              isDisable: false,
            });
            callback(response.data);
          }
          else if (response.statusCode !== 1000) {
            setError(response.statusMessage);
          }
          setProcessing(false);
        } else {
          const response = await callToService(
            createRevertServiceEndpoint(id, manualId),
            "PUT",
            fields,
          );
          if (response.data) {
            setData(response.data);
            setLocalFields({
              agent: response.data.agent,
              parentAgent: response.data.parentAgent,
              isDisable: false,
            });
            callback(response.data);
          }
          else if (response.statusCode !== 1000) {
            setError(response.statusMessage);
          }
          setProcessing(false);
        }
        setProcessing(false);
      } catch (error) {
        setError((error as Error).message);
        setProcessing(false);
      }
    },
    [callToService, fields, id]
  );
  const setFields = (field: string, value: any) => {
    setLocalFields({ ...fields, [field]: value });
  };

  return (
    <AgentSharingDeleteServiceContext.Provider
      value={{
        id,
        data,
        fields,
        isProcessing,
        error,
        setId,
        setFields,
        fetch,
        softDelete,
      }}>
      {children}
    </AgentSharingDeleteServiceContext.Provider>
  );
};
