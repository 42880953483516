import {
  FC,
  useContext,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { AuthContext } from "./AuthContext";
import { SelfContextProvider } from "./SelfContext";
import { ColorRing } from "react-loader-spinner";

const SessionVerifier: FC<any> = ({ children }) => {
  const contextAuth = useContext(AuthContext);
  const [isReady, setReady] = useState(false);
  const onVerifySession = useCallback(async () => {
    await contextAuth.onCheckToken();
    setReady(true);
  }, [contextAuth]);

  const shouldVerify = useRef(true);

  useEffect(() => {
    if (shouldVerify.current) {
      shouldVerify.current = false;
      onVerifySession();
    }
  });

  return (
    <SelfContextProvider>
      {isReady ? (
        children
      ) : (
        <div className="w-full h-full flex justify-center items-center flex-col">
          <ColorRing
            visible={true}
            height="90"
            width="90"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
          />
        </div>
      )}
    </SelfContextProvider>
  );
};

export default SessionVerifier;
