import { FC, useContext, useEffect } from "react";
import {
  UserEditServiceContext,
  UserEditServiceContextProvider,
} from "../../../contexts/services/user/UserEditServiceContext";
import CrudModal from "../../CrudModal";

type UserModalProps = {
  id: string;
  mode: string;
  isOpen: boolean;
  setOpen: Function;
  afterSave?: Function;
};
const UserModal: FC<UserModalProps> = ({
  id,
  mode,
  isOpen,
  setOpen,
  afterSave,
}) => {
  const contextUser = useContext(UserEditServiceContext);
  useEffect(() => {
    if (id) {
      contextUser.setId(id);
      contextUser.fetch(id);
    }
  }, [id, contextUser]);

  return (
    <CrudModal
      template={[
        {
          name: "ชื่อผู้ใช้",
          field: "username",
          placeholder: "ชื่อผู้ใช้",
          value: contextUser.data.username,
          type: "text",
          isDisable: true,
        },
        {
          name: "สมัครวันที่",
          field: "createdAt",
          placeholder: "วันเริ่มต้นที่ต้องการค้นหา",
          value: contextUser.data.createdAt,
          type: "datetime",
          isDisable: true,
        },
        {
          name: "เดิมพันสูงสุด",
          field: "betLimit",
          placeholder: "อัตตราเดิมพันสูงสุดของผู้เล่น",
          value: contextUser.fields.betLimit,
          type: "text",
        },
        {
          name: "ห้ามการเดิมพัน",
          field: "locked",
          placeholder: "การอนุญาติเข้าเล่นเกมของผู้ใช้",
          value: contextUser.fields.locked,
          type: "boolean",
          isDisable: mode === "info" ? false : false,
        },
      ]}
      error={contextUser.error}
      isModalOpen={isOpen}
      onToggleModal={() => setOpen(!isOpen)}
      onUpdateField={(field: string, value: any) => {
        contextUser.setFields(field, value);
      }}
      onSubmit={
        mode === "info"
          ? () => {
            contextUser.saveFields(() => {
                setOpen(false);
                afterSave && afterSave(window.location.reload());
              });
            }:undefined
      }
    />
  );
};

const UserModalHoc = (props: UserModalProps) => {
  return (
    <UserEditServiceContextProvider>
      <UserModal {...props} />
    </UserEditServiceContextProvider>
  );
};
export default UserModalHoc;
