import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import ReactDOM from "react-dom/client";
import Modal from "react-modal";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import App from "./Router";

Modal.setAppElement("#root");
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
