import axios from "axios";
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useState,
  useRef,
} from "react";
import { AuthContext } from "./AuthContext";

export const SelfContext = createContext({
  user: {
    username: "",
    type: "",
    id: "",
  },
  onSyncSelf: () => {},
});

export const SelfContextProvider: FC<any> = ({ children }) => {
  const contextAuth = useContext(AuthContext);
  const [user, setUser] = useState({
    username: "",
    type: "",
    id: "",
  });
  const shouldVerify = useRef(true);

  const onSyncSelf = useCallback(async () => {
    if (shouldVerify.current) {
      shouldVerify.current = false;
      try {
        const request = await axios.post(
          `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/admin/self`,
          {},
          {
            headers: {
              Authorization: `Bearer ${contextAuth.token}`,
            },
          }
        );
        if (request.data.data) setUser(request.data.data);
      } catch (error) {}
    }
  }, [contextAuth.token]);

  return (
    <SelfContext.Provider
      value={{
        user,
        onSyncSelf,
      }}
    >
      {children}
    </SelfContext.Provider>
  );
};
