import { FC, useContext, useState } from "react";
import CollectionTable from "../components/collection/CollectionTable";
import NavigationPanel from "../components/NavigationPanel";
import {
  TransactionFromReportServiceContext,
  TransactionFromReportServiceContextProvider,
} from "../contexts/services/summary/TransactionFromReportServiceContext";
import SessionVerifier from "../contexts/SessionContext";
import SummaryDashboard from "../components/SummaryDashboard";
import CollectionFilter from "../components/collection/CollectionFilter";
import SumTranDetailModalHoc from "../components/modal/summary-modal/SumTransactionDetailModal";

const SummaryTransactionFromReportScreen: FC<any> = () => {
  const contextTransactions = useContext(TransactionFromReportServiceContext);

  const [isId, setId] = useState("");
  const [listId, setListId] = useState("");
  const [isSumTranDetailModalOpen, setSumTranDetailModalOpen] = useState(false);

  return (
    <NavigationPanel>
      <div className="relative pt-6 mt-4">
        <CollectionFilter
          template={[
            {
              name: "รหัส Agent",
              field: "agentID",
              placeholder: "รหัส Agent",
              value: contextTransactions.id,
              type: "textID",
            },
            {
              name: "GameBrand",
              field: "gameBrand",
              placeholder: "Game Brand",
              value: contextTransactions.query.gameBrand,
              type: "text",
            },
            {
              name: "ตั้งแต่วันที่",
              field: "startDate",
              placeholder: "วันเริ่มต้นที่ต้องการค้นหา",
              value: contextTransactions.query.startDate,
              type: "datetime",
            },
            {
              name: "ถึงวันที่",
              field: "endDate",
              placeholder: "วันสิ้นสุดที่ต้องการค้นหา",
              value: contextTransactions.query.endDate,
              type: "datetime",
            },
          ]}
          onUpdateFilter={(field: any, value: any) =>
            contextTransactions.setQuery(field, value)
          }
          onUpdateID={(searchID: any) => {
            contextTransactions.setId(searchID);
          }}
          onSearch={() => contextTransactions.onFetch()}
        />
        <SummaryDashboard />
        <CollectionTable
          texts={{
            title: "Transaction From Report",
            description: "Transaction From Report",
            buttonTextEdit: "ตรวจสอบรายการ",
          }}
          template={[
            { name: "User", field: "user" },
            { name: "Username", field: "username" },
            { name: "Bet ID", field: "betId" },
            { name: "Bet Type", field: "betType" },
            { name: "ประเภทการ Bet จากเกมส์", field: "betTypeFromGame" },
            { name: "แบรนด์เกมส์", field: "gameBrand" },
            { name: "รหัสเกมส์", field: "gameCode" },
          ]}
          collection={contextTransactions.collection.map((item: any) => ({
            ...item,
          }))}
          events={{
            onEdit: (data: { _id: string; agent: string }) => {
              setListId(data.agent);
              setId(data._id);
              setSumTranDetailModalOpen(true);
            },
          }}
        />
        <SumTranDetailModalHoc
          id={isId}
          listId={listId}
          isOpen={isSumTranDetailModalOpen}
          setOpen={(status: boolean) => setSumTranDetailModalOpen(status)}
          afterSave={() => {}}
          mode={"info"}
        />
      </div>
    </NavigationPanel>
  );
};

const SummaryTransactionFromReportScreenHoc = (props: any) => {
  return (
    <SessionVerifier>
      <TransactionFromReportServiceContextProvider>
        <SummaryTransactionFromReportScreen {...props} />
      </TransactionFromReportServiceContextProvider>
    </SessionVerifier>
  );
};
export default SummaryTransactionFromReportScreenHoc;
