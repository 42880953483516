import { createContext, FC, useCallback, useState } from "react";
import useCallToService from "../../../hooks/useCallToService";
import { IUser } from "../../../models/entities/User";

interface IUserWithDate extends IUser {
  createdAt: Date;
  updatedAt: Date;
}

interface IUserEditServiceField extends Partial<IUser> {}

type TUserEditServiceProps = {
  id: string;
  data: IUserWithDate;
  fields: IUserEditServiceField;
  isProcessing: boolean;
  error: string;
  setId: Function;
  setFields: Function;
  saveFields: Function;
  fetch: Function;
};

const defaultContext: TUserEditServiceProps = {
  id: "",
  data: {
    username: "",
    agent: "",
    betLimit: 0,
    locked: false,
    activeGamePlaying: "",
    lastGamePlaying: [],
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  fields: {
    username: "",
    betLimit: 0,
  },
  isProcessing: false,
  error: "",
  setId: () => {},
  setFields: () => {},
  saveFields: () => {},
  fetch: () => {},
};
const createGetServiceEndpoint = (id: string, manualId?: string) =>
  `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/user/get/${manualId || id}`;
const createUpdateServiceEndpoint = (id: string, manualId?: string) =>
  `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/user/update/${
    manualId || id
  }`;
export const UserEditServiceContext = createContext(defaultContext);
export const UserEditServiceContextProvider: FC<any> = ({ children }) => {
  const [id, setId] = useState(defaultContext.id);
  const [data, setData] = useState<IUserWithDate>(defaultContext.data);
  const [fields, setLocalFields] = useState(defaultContext.fields);
  const [isProcessing, setProcessing] = useState(false);
  const [error, setError] = useState("");
  const [callToService] = useCallToService();

  const fetch = useCallback(
    async (manualId?: string) => {
      setError("");
      setProcessing(true);
      try {
        const response = await callToService(
          createGetServiceEndpoint(id, manualId),
          "POST",
          {}
        );

        if (response.data) {
          setData(response.data);
          setLocalFields({
            username: response.data.username,
            betLimit: response.data.betLimit,
          });
        } else if (response.statusCode !== 1000) {
          setError(response.statusMessage);
        }
        setProcessing(false);
      } catch (error) {
        setError((error as Error).message);
        setProcessing(false);
      }
    },
    [callToService, id]
  );

  const saveFields = useCallback(
    async (callback: Function) => {
      setProcessing(true);
      try {
        const response = await callToService(
          createUpdateServiceEndpoint(id),
          "POST",
          fields
        );

        if (response.data) {
          setData(response.data);
          callback(response.data);
        } else if (response.statusCode !== 1000) {
          setError(response.statusMessage);
        }
        setProcessing(false);
      } catch (error) {
        setError((error as Error).message);
        setProcessing(false);
      }
    },
    [callToService, fields, id]
  );

  const setFields = (field: string, value: any) => {
    setLocalFields({ ...fields, [field]: value });
  };

  return (
    <UserEditServiceContext.Provider
      value={{
        id,
        data,
        fields,
        isProcessing,
        error,
        setId,
        setFields,
        saveFields,
        fetch,
      }}>
      {children}
    </UserEditServiceContext.Provider>
  );
};
