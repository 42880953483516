import { FC, useContext } from "react";
import CollectionFilter from "../components/collection/CollectionFilter";
import CollectionTable from "../components/collection/CollectionTable";
import NavigationPanel from "../components/NavigationPanel";
import {
  AgentMonthlyReportServiceContext,
  AgentMonthlyReportServiceContextProvider,
} from "../contexts/services/summary/AgentMonthlyReportServiceContext";
import SessionVerifier from "../contexts/SessionContext";
import SummaryDashboard from "../components/SummaryDashboard";

const SummaryAgentMonthlyScreen: FC<any> = () => {
  const contextMonthlyReport = useContext(AgentMonthlyReportServiceContext);

  return (
    <NavigationPanel>
      <div className="relative pt-6 mt-4">
        <CollectionFilter
          template={[
            {
              name: "Agent ID",
              field: "agentID",
              placeholder: "รหัส Agent",
              value: contextMonthlyReport.id,
              type: "textID",
            },
            {
              name: "เดือน",
              field: "month",
              placeholder: "เดือนที่ต้องการค้นหา",
              value: contextMonthlyReport.query.month,
              type: "text",
            },
            {
              name: "ปี",
              field: "year",
              placeholder: "ปีที่ต้องการค้นหา",
              value: contextMonthlyReport.query.year,
              type: "text",
            },
          ]}
          onUpdateFilter={(field: any, value: any) =>
            contextMonthlyReport.setQuery(field, value)
          }
          onUpdateID={(searchID: any) => {
            contextMonthlyReport.setId(searchID);
          }}
          onSearch={() => contextMonthlyReport.onFetch()}
        />
        <SummaryDashboard />
        <CollectionTable
          texts={{
            title: "Agent Monthly",
            description: "Agent Monthly",
            buttonTextAdd: "สรุปผล",
            buttonTextEdit: "รายงานผลแต่ละ Agent",
          }}
          template={[
            { name: "เดือน", field: "month" },
            { name: "ปี", field: "year" },
          ]}
          collection={contextMonthlyReport.collection.map((item: any) => ({
            ...item,
          }))}
        />
      </div>
    </NavigationPanel>
  );
};

const SummaryAgentMonthlyScreenHoc = (props: any) => {
  return (
    <SessionVerifier>
      <AgentMonthlyReportServiceContextProvider>
        <SummaryAgentMonthlyScreen {...props} />
      </AgentMonthlyReportServiceContextProvider>
    </SessionVerifier>
  );
};
export default SummaryAgentMonthlyScreenHoc;
