import { createContext, FC, useCallback, useState } from "react";
import useCallToService from "../../../hooks/useCallToService";
import { setQueryFunction } from "../../StandardContextUtils";

type TransactionFromReportQuery = {
  user: string;
  username: string;
  betId: string;
  betType: string;
  betTypeFromGame: string;
  gameBrand: string;
  gameCode: string;
  startDate: string;
  endDate: string;
  page: number;
  limit: number;
};

type TransactionFromReportServiceContextProps = {
  id: string;
  query: TransactionFromReportQuery;
  collection: any[];
  setQuery: (field: string, value: any) => void;
  onFetch: (token?: string) => void;
  setId: (searchID: any) => void;
};

const defaultContext: TransactionFromReportServiceContextProps = {
  id: "",
  query: {
    user: "",
    username: "",
    betId: "",
    betType: "",
    betTypeFromGame: "",
    gameBrand: "",
    gameCode: "",
    startDate: "",
    endDate: "",
    page: 1,
    limit: 10,
  },
  collection: [],
  setId: (searchID: any) => { },
  setQuery: (field: string, value: any) => { },
  onFetch: (token?: string) => { },
};
const getServiceEndpoint = (id: string, manualId?: string) =>
  `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/summary/${manualId || id}/list`;
export const TransactionFromReportServiceContext = createContext(defaultContext);
export const TransactionFromReportServiceContextProvider: FC<any> = ({ children }) => {
  const [collection, setCollection] = useState([]);
  const [id, setId] = useState(defaultContext.id);
  const [query, setLocalQuery] = useState(defaultContext.query);
  const [callToService] = useCallToService();

  const onFetch = useCallback(async (manualId?: string) => {
    try {
      const response = await callToService(getServiceEndpoint(id, manualId), "POST", query);
      if (response.data) setCollection(response.data);
    } catch (error) {
    }
  }, [callToService, query, id]);
  const setQuery = setQueryFunction(query, setLocalQuery);

  return (
    <TransactionFromReportServiceContext.Provider
      value={{
        id,
        query,
        collection,
        setQuery,
        onFetch,
        setId,
      }}>
      {children}
    </TransactionFromReportServiceContext.Provider>
  );
};
