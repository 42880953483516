import { FC, useContext, useEffect } from "react";
import {
  AgentSharingEditServiceContext,
  AgentSharingEditServiceContextProvider,
} from "../../contexts/services/agentSharing/AgentSharingEditServiceContext";
import CrudModal from "../CrudModal";

type AgentSharingEditModalProps = {
  id: string;
  mode: string;
  isOpen: boolean;
  setOpen: Function;
  afterSave?: Function;
};
const AgentSharingEditModal: FC<AgentSharingEditModalProps> = ({
  id,
  mode,
  isOpen,
  setOpen,
  afterSave,
}) => {
  const contextAgentSharing = useContext(AgentSharingEditServiceContext);
  useEffect(() => {
    if (id) {
      contextAgentSharing.setId(id);
      contextAgentSharing.fetch(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <CrudModal
      template={[
        {
          name: "Agent",
          field: "agent",
          placeholder: "Agent",
          value: contextAgentSharing.data.agent,
          type: "text",
          isDisable: true,
        },
        {
          name: "Parent Agent",
          field: "parentAgent",
          placeholder: "632c08dd14f1957f8c3500c0",
          value: contextAgentSharing.fields.parentAgent,
          type: "text",//collection
        },
        {
          name: "Percentage",
          field: "parentPercentage",
          placeholder: "Percentage",
          value: contextAgentSharing.fields.parentPercentage,
          type: "text",
        },

      ]}
      error={contextAgentSharing.error}
      isModalOpen={isOpen}
      onToggleModal={() => setOpen(!isOpen)}
      onUpdateField={(field: string, value: any) => {
        contextAgentSharing.setFields(field, value);
      }}
      onSubmit={
        mode === "info"
          ? () => {
            contextAgentSharing.saveFields(() => {
              setOpen(false);
              afterSave && afterSave();
            });
          } : undefined
      }
    />
  );
};

const AgentSharingEditModalHoc = (props: AgentSharingEditModalProps) => {
  return (
    <AgentSharingEditServiceContextProvider>
      <AgentSharingEditModal {...props} />
    </AgentSharingEditServiceContextProvider>
  );
};
export default AgentSharingEditModalHoc;
