import axios from "axios";
import { useCallback, useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";

type CallToServiceResponse = {
  statusCode: number | string;
  statusMessage: string;
  data: any;
};
const useCallToService = () => {
  const contextAuth = useContext(AuthContext);
  const callToService = useCallback(
    async (
      path: string,
      method: string,
      body?: any
    ): Promise<CallToServiceResponse> => {
      try {
        let response = null;
        if (method === "GET")
          response = await axios.get(path, {
            headers: {
              Authorization: `Bearer ${contextAuth.token}`,
            },
          });
        if (method === "POST")
          response = await axios.post(path, body, {
            headers: {
              Authorization: `Bearer ${contextAuth.token}`,
            },
          });
          if (method === "PUT")
          response = await axios.put(path, body, {
            headers: {
              Authorization: `Bearer ${contextAuth.token}`,
            },
          });
          if (method === "DELETE")
          response = await axios.delete(path, {
            headers: {
              Authorization: `Bearer ${contextAuth.token}`,
            },
            data:body
          });

        if (response)
          return {
            statusCode: response?.data.status,
            statusMessage: response?.data.error || "Success",
            data: response?.data.data,
          };
        else
          return {
            statusCode: 4000,
            statusMessage: "Unable to process request",
            data: null,
          };
      } catch (error: any) {
        if (error.response && error.response.data)
          return {
            statusCode: error.response.data.status,
            statusMessage: error.response.data.error,
            data: null,
          };
        else
          return {
            statusCode: 5000,
            statusMessage: "Unable to process request",
            data: null,
          };
      }
    },
    [contextAuth]
  );

  return [callToService];
};

export default useCallToService;
