import { FC, useContext, useEffect } from "react";
import {
  AdapterEditServiceContext,
  AdapterEditServiceContextProvider,
} from "../../contexts/services/adapter/adapter-edit-service-context";
import CrudModal from "../CrudModal";

type AdapterEditModalProps = {
  id: string;
  mode: string;
  isOpen: boolean;
  setOpen: Function;
  afterSave?: Function;
};
const AdapterEditModal: FC<AdapterEditModalProps> = ({
  id,
  mode,
  isOpen,
  setOpen,
  afterSave,
}) => {
  const contextAdapter = useContext(AdapterEditServiceContext);
  useEffect(() => {
    if (id) {
      contextAdapter.setId(id);
      contextAdapter.fetch(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <CrudModal
      template={[
        {
          name: "ชื่อค่ายเกม",
          field: "name",
          placeholder: "ชื่อค่ายเกม",
          value: contextAdapter.data.name,
          type: "text",
          isDisable: true,
        },
        {
          name: "รหัสค่ายเกม",
          field: "codename",
          placeholder: "รหัสค่ายเกม",
          value: contextAdapter.fields.codename,
          type: "text",
          isDisable: false,
        },
        {
          name: "Link ค่ายเกม",
          field: "endpoint",
          placeholder: "Link ค่ายเกม",
          value: contextAdapter.fields.endpoint,
          type: "text",
        },
        {
          name: "ประเภทค่ายเกม",
          field: "type",
          placeholder: "ประเภทค่ายเกม",
          value: contextAdapter.fields.type,
          type: "text",
          isDisable: false,
        },
        {
          name: "Link โลโก้เกม",
          field: "logo",
          placeholder: "โลโก้เกม",
          value: contextAdapter.fields.logo,
          type: "text",
          isDisable: false,
        },
      ]}
      error={contextAdapter.error}
      isModalOpen={isOpen}
      onToggleModal={() => setOpen(!isOpen)}
      onUpdateField={(field: string, value: any) => {
        contextAdapter.setFields(field, value);
      }}
      onSubmit={
        mode === "info"
          ? () => {
              contextAdapter.saveFields(() => {
                setOpen(false);
                afterSave && afterSave();
              });
            }
          : undefined
      }
    />
  );
};

const AdapterEditModalHoc = (props: AdapterEditModalProps) => {
  return (
    <AdapterEditServiceContextProvider>
      <AdapterEditModal {...props} />
    </AdapterEditServiceContextProvider>
  );
};
export default AdapterEditModalHoc;
