import { createContext, FC, useCallback, useState } from "react";
import useCallToService from "../../../hooks/useCallToService";
import { IAdapter } from "../../../models/entities/Adapter";

type IAdapterDeleteServiceField = {
  name: string;
  codename: string;
  isDisable: boolean;
};

type AdapterDeleteServiceProps = {
  id: string;
  data: IAdapter;
  fields: IAdapterDeleteServiceField;
  isProcessing: boolean;
  error: string;
  setId: Function;
  setFields: Function;
  fetch: Function;
  softDelete: Function;
};

const defaultContext: AdapterDeleteServiceProps = {
  id: "",
  data: {
    name: "",
    codename: "",
    endpoint: "",
    type: "",
    logo: "",
    isDisable: false,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  fields: {
    name: "",
    codename: "",
    isDisable: false,
  },
  isProcessing: false,
  error: "",
  setId: () => {},
  setFields: () => {},
  fetch: () => {},
  softDelete: () => {},
};
const createGetServiceEndpoint = (id: string, manualId?: string) =>
  `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/adapter/get/${
    manualId || id
  }`;
const createDeleteServiceEndpoint = (id: string, manualId?: string) =>
  `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/adapter/delete/${
    manualId || id
  }`;
const createRevertServiceEndpoint = (id: string, manualId?: string) =>
  `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/adapter/revert/${
    manualId || id
  }`;

export const AdapterDeleteServiceContext = createContext(defaultContext);
export const AdapterDeleteServiceContextProvider: FC<any> = ({ children }) => {
  const [id, setId] = useState(defaultContext.id);
  const [data, setData] = useState<IAdapter>(defaultContext.data);
  const [fields, setLocalFields] = useState(defaultContext.fields);
  const [isProcessing, setProcessing] = useState(false);
  const [error, setError] = useState("");
  const [callToService] = useCallToService();

  const fetch = useCallback(
    async (manualId?: string) => {
      setError("");
      setProcessing(true);
      try {
        const response = await callToService(
          createGetServiceEndpoint(id, manualId),
          "POST",
          {}
        );

        if (response.data) {
          setData(response.data);
          setLocalFields({
            name: response.data.username,
            codename: response.data.codename,
            isDisable: response.data.isDisable,
          });
        } else if (response.statusCode !== 1000) {
          setError(response.statusMessage);
        }
        setProcessing(false);
      } catch (error) {
        setError((error as Error).message);
        setProcessing(false);
      }
    },
    [callToService, id]
  );

  const softDelete = useCallback(
    async (callback: Function, manualId?: string) => {
      setProcessing(true);
      try {
        if (fields.isDisable === false) {
          const response = await callToService(
            createDeleteServiceEndpoint(id, manualId),
            "DELETE",
            fields
          );
          if (response.data) {
            setData(response.data);
            setLocalFields({
              name: response.data.username,
              codename: response.data.codename,
              isDisable: false,
            });
            callback(response.data);
          } else if (response.statusCode !== 1000) {
            setError(response.statusMessage);
          }
          setProcessing(false);
        } else {
          const response = await callToService(
            createRevertServiceEndpoint(id, manualId),
            "PUT",
            fields
          );
          if (response.data) {
            setData(response.data);
            setLocalFields({
              name: response.data.username,
              codename: response.data.codename,
              isDisable: false,
            });
            callback(response.data);
          } else if (response.statusCode !== 1000) {
            setError(response.statusMessage);
          }
          setProcessing(false);
        }
        setProcessing(false);
      } catch (error) {
        setError((error as Error).message);
        setProcessing(false);
      }
    },
    [callToService, fields, id]
  );
  const setFields = (field: string, value: any) => {
    setLocalFields({ ...fields, [field]: value });
  };
  return (
    <AdapterDeleteServiceContext.Provider
      value={{
        id,
        data,
        fields,
        isProcessing,
        error,
        setId,
        setFields,
        fetch,
        softDelete,
      }}
    >
      {children}
    </AdapterDeleteServiceContext.Provider>
  );
};
