import { FC, useContext, useEffect } from "react";
import {
  AgentDeleteServiceContext,
  AgentDeleteServiceContextProvider,
} from "../../contexts/services/agent/AgentDeleteServiceContext";
import CrudModalDelete from "../CrudModalDelete";

type AgentDeleteModalProps = {
  id: string;
  mode: string;
  isOpen: boolean;
  setOpen: Function;
  afterSave?: Function;
};
const AgentDeleteModal: FC<AgentDeleteModalProps> = ({
  id,
  mode,
  isOpen,
  setOpen,
  afterSave,
}) => {
  const contextAgent = useContext(AgentDeleteServiceContext);
  useEffect(() => {
    if (id) {
      contextAgent.setId(id);
      contextAgent.fetch(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <CrudModalDelete
      error={contextAgent.error}
      isModalOpen={isOpen}
      onToggleModal={() => setOpen(!isOpen)}
      onSubmit={
        mode === "info"
          ? () => {
              contextAgent.softDelete(() => {
                setOpen(false);
                afterSave && afterSave();
              });
            }
          : undefined
      }
    />
  );
};

const AgentDeleteModalHoc = (props: AgentDeleteModalProps) => {
  return (
    <AgentDeleteServiceContextProvider>
      <AgentDeleteModal {...props} />
    </AgentDeleteServiceContextProvider>
  );
};
export default AgentDeleteModalHoc;
