import { createContext, FC, useCallback, useState } from "react";
import useCallToService from "../../../hooks/useCallToService";
import { IAgent } from "../../../models/entities/Agent";

type IAgentDeleteServiceField = {
  username: string;
  locked: boolean;
  // deletedAt: Date;
};

type IAgentDeleteServiceProps = {
  id: string;
  data: IAgent;
  fields: IAgentDeleteServiceField;
  isProcessing: boolean;
  error: string;
  setId: Function;
  setFields: Function;
  fetch: Function;
  softDelete: Function;
};

const defaultContext: IAgentDeleteServiceProps = {
  id: "",
  data: {
    username: "",
    endpoint: "",
    secret: "",
    apiKey: "",
    apiKeyEncoded: "",
    games: [],
    prefix: "",
    code: "",
    agentType: "",
    createdBy: "",
    locked: false,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  fields: {
    username: "",
    locked: false,
  },
  isProcessing: false,
  error: "",
  setId: () => { },
  setFields: () => { },
  fetch: () => { },
  softDelete: () => { },
};
const createGetServiceEndpoint = (id: string, manualId?: string) =>
  `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/agent/get/${manualId || id}`;
const createDeleteServiceEndpoint = (id: string, manualId?: string) =>
  `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/agent/delete/${manualId || id}`;
const createRevertServiceEndpoint = (id: string, manualId?: string) =>
  `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/agent/revert/${manualId || id}`;
export const AgentDeleteServiceContext = createContext(defaultContext);
export const AgentDeleteServiceContextProvider: FC<any> = ({ children }) => {
  const [id, setId] = useState(defaultContext.id);
  const [data, setData] = useState<IAgent>(defaultContext.data);
  const [fields, setLocalFields] = useState(defaultContext.fields);
  const [isProcessing, setProcessing] = useState(false);
  const [error, setError] = useState("");
  const [callToService] = useCallToService();

  const fetch = useCallback(
    async (manualId?: string) => {
      setError("");
      setProcessing(true);
      try {
        const response = await callToService(
          createGetServiceEndpoint(id, manualId),
          "POST",
          {}
        );

        if (response.data) {
          setData(response.data);
          setLocalFields({
            username: response.data.username,
            locked: response.data.locked,
          });
        } else if (response.statusCode !== 1000) {
          setError(response.statusMessage);
        }
        setProcessing(false);
      } catch (error) {
        setError((error as Error).message);
        setProcessing(false);
      }
    },
    [callToService, id]
  );

  const softDelete = useCallback(
    async (callback: Function, manualId?: string) => {
      setProcessing(true);
      try {
        if (fields.locked === false) {
          const response = await callToService(
            createDeleteServiceEndpoint(id, manualId),
            "DELETE",
            fields,
          );
          if (response.data) {
            setData(response.data);
            setLocalFields({
              username: response.data.username,
              locked: response.data.locked,
            });
            callback(response.data);
          } else if (response.statusCode !== 1000) {
            setError(response.statusMessage);
          }
          setProcessing(false);
        }
        else {
          const response = await callToService(
            createRevertServiceEndpoint(id, manualId),
            "PUT",
            fields,
          );
          if (response.data) {
            setData(response.data);
            setLocalFields({
              username: response.data.username,
              locked: false,
            });
            callback(response.data);
          }
          else if (response.statusCode !== 1000) {
            setError(response.statusMessage);
          }
          setProcessing(false);
        }
      } catch (error) {
        setError((error as Error).message);
        setProcessing(false);
      }
    },
    [callToService, fields, id]
  );
  const setFields = (field: string, value: any) => {
    setLocalFields({ ...fields, [field]: value });
  };
  return (
    <AgentDeleteServiceContext.Provider
      value={{
        id,
        data,
        fields,
        isProcessing,
        error,
        setId,
        setFields,
        softDelete,
        fetch,
      }}>
      {children}
    </AgentDeleteServiceContext.Provider>
  );
};
