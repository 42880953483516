import { createContext, FC, useCallback, useState } from "react";
import useCallToService from "../../../hooks/useCallToService";
import { setQueryFunction } from "../../StandardContextUtils";

type GameMonthlyReportQuery = {
  month: string;
  year: string;
};

type GameMonthlyReportServiceContextProps = {
  id: string;
  query: GameMonthlyReportQuery;
  collection: any[];
  setQuery: (field: string, value: any) => void;
  onFetch: (token?: string) => void;
  setId: (searchID: any) => void;
};

const defaultContext: GameMonthlyReportServiceContextProps = {
  id: "",
  query: {
    month: "",
    year: "",
  },
  collection: [],
  setId: (searchID: any) => { },
  setQuery: (field: string, value: any) => { },
  onFetch: (token?: string) => { },
};
const getServiceEndpoint = (id: string, manualId?: string) =>
  `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/summary/${manualId || id}/game/monthly/all`;
export const GameMonthlyReportServiceContext = createContext(defaultContext);
export const GameMonthlyReportServiceContextProvider: FC<any> = ({ children }) => {
  const [collection, setCollection] = useState([]);
  const [id, setId] = useState(defaultContext.id);
  const [query, setLocalQuery] = useState(defaultContext.query);
  const [callToService] = useCallToService();

  const onFetch = useCallback(async (manualId?: string) => {
    try {
      const response = await callToService(getServiceEndpoint(id, manualId), "POST", query);
      if (response.data) setCollection(response.data);
    } catch (error) {
    }
  }, [callToService, query, id]);
  const setQuery = setQueryFunction(query, setLocalQuery);

  return (
    <GameMonthlyReportServiceContext.Provider
      value={{
        id,
        query,
        collection,
        setQuery,
        onFetch,
        setId,
      }}>
      {children}
    </GameMonthlyReportServiceContext.Provider>
  );
};
