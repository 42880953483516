import { createContext, FC, useCallback, useState } from "react";
import { setQueryFunction } from "../../StandardContextUtils";
import useCallToService from "../../../hooks/useCallToService";

type AgentSharingListQuery = {
  agent: string;
  parentAgent: string;
  username: string;
  prefix: string;
  page: number;
  limit: number;
};

type AgentSharingListServiceContextProps = {
  query: AgentSharingListQuery;
  collection: any[];
  setQuery: (field: string, value: any) => void;
  onFetch: (token?: string) => void;
};

const defaultContext: AgentSharingListServiceContextProps = {
  query: {
    agent: "",
    parentAgent: "",
    username: "",
    prefix: "",
    page: 1,
    limit: 10,
  },
  collection: [],
  setQuery: (field: string, value: any) => { },
  onFetch: (token?: string) => { },
};
const getServiceEndpoint = () =>
  `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/agent-sharing/list`;
export const AgentSharingListServiceContext = createContext(defaultContext);
export const AgentSharingListServiceContextProvider: FC<any> = ({ children }) => {
  const [collection, setCollection] = useState([]);
  const [query, setLocalQuery] = useState(defaultContext.query);
  // const [query, setQuery] = useState(defaultContext.query);
  const [callToService] = useCallToService();

  const onFetch = useCallback(async () => {
    try {
      const response = await callToService(getServiceEndpoint(), "POST", query);
      if (response.data) {
        setCollection(response.data);
      }
    } catch (error) {
    }
  }, [callToService, query]);
  const setQuery = setQueryFunction(query, setLocalQuery);

  return (
    <AgentSharingListServiceContext.Provider
      value={{
        query,
        collection,
        setQuery,
        onFetch,
      }}>
      {children}
    </AgentSharingListServiceContext.Provider>
  );
};
