import { FC, useCallback, useContext, useEffect, useState, useRef } from "react";
import CollectionFilter from "../components/collection/CollectionFilter";
import CollectionTable from "../components/collection/CollectionTable";
import NavigationPanel from "../components/NavigationPanel";
import UserModalHoc from "../components/screens/user/UserModal";
import {
  UserListServiceContext,
  UserListServiceContextProvider,
} from "../contexts/services/user/UserListServiceContext";
import SessionVerifier from "../contexts/SessionContext";
import UserCreateModalHoc from "../components/modal/UserCreateModal";
import { UserCreateServiceContext } from "../contexts/services/user/UserCreateServiceContext";
import { UserDeleteServiceContext } from "../contexts/services/user/UserDeleteServiceContext";

const UserScreen: FC<any> = () => {
  const contextUsers = useContext(UserListServiceContext);
  const contextCreateUsers = useContext(UserCreateServiceContext);
  const [id, setId] = useState("");
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const [isUserCreateModalOpen, setUserCreateModalOpen] = useState(false);

  const [, setDelId] = useState("");
  const contextDeleteUsers = useContext(UserDeleteServiceContext);

  const fetchCollection = useCallback(() => {
    contextUsers.onFetch();
  }, [contextUsers]);

  const shouldFetch = useRef(true);

  useEffect(() => {
    if (shouldFetch.current) {
      shouldFetch.current = false;
      fetchCollection();
    }
  });

  return (
    <NavigationPanel>
      <div className="relative pt-6 mt-4">
        <CollectionFilter
          template={[
            {
              name: "ชื่อผู้ใช้",
              field: "username",
              placeholder: "ชื่อผู้ใช้",
              value: contextUsers.query.username,
              type: "text",
            },
            {
              name: "ตั้งแต่วันที่",
              field: "startDate",
              placeholder: "วันเริ่มต้นที่ต้องการค้นหา",
              value: contextUsers.query.startDate,
              type: "datetime",
            },
            {
              name: "ถึงวันที่",
              field: "endDate",
              placeholder: "วันสิ้นสุดที่ต้องการค้นหา",
              value: contextUsers.query.endDate,
              type: "datetime",
            },
          ]}
          onUpdateFilter={(field: any, value: any) =>
            contextUsers.setQuery(field, value)
          }
          onSearch={() => contextUsers.onFetch()}
        />
        <CollectionTable
          texts={{
            title: "รายชื่อผู้ใช้",
            description: "รายชื่อผู้ใช้ในระบบทั้งหมด",
            buttonTextAdd: "เพิ่มผู้ใช้",
            buttonTextEdit: "แก้ไข",
            buttonTextDelete: "ลบ",
          }}
          template={[
            {
              name: "ชื่อผู้ใช้",
              field: "usernameWithOutPrefix",
              isEvent: true,
              eventCallback: (data: { _id: string }) => {
                setId(data._id);
                setInfoModalOpen(true);
              },
            },
            { name: "เดิมพันได้สูงสุด", field: "betLimit" },
            {
              name: "ห้ามการเดิมพัน",
              placeholder: "การอนุญาติเข้าเล่นเกมของผู้ใช้",
              type: "boolean",
              field: "locked",
            },
            { name: "สมัครวันที่", field: "createdAt" },
            { name: "เกมล่าสุด", field: "activeGamePlaying" },
          ]}
          collection={contextUsers.collection.map((item: any) => ({
            ...item,
            usernameWithOutPrefix: item.username,
          }))}
          events={{
            onAdd: (data: { codename: string }) => {
              setUserCreateModalOpen(true);
            },
            onDelete: (data: { _id: string }) => {
              setDelId(data._id);
              contextDeleteUsers.softDelete(data._id);
            }
          }}
        />
        <UserCreateModalHoc
          isOpen={isUserCreateModalOpen}
          setOpen={(status: boolean) => setUserCreateModalOpen(status)}
          afterSave={() => contextCreateUsers.saveFields}
          mode={"info"}
        />
      </div>
      <UserModalHoc
        id={id}
        isOpen={isInfoModalOpen}
        setOpen={(status: boolean) => setInfoModalOpen(status)}
        afterSave={() => contextUsers.onFetch()}
        mode={"info"}
      />
    </NavigationPanel>
  );
};

const UserScreenHoc = (props: any) => {
  return (
    <SessionVerifier>
      <UserListServiceContextProvider>
        <UserScreen {...props} />
      </UserListServiceContextProvider>
    </SessionVerifier>
  );
};
export default UserScreenHoc;
