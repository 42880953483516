import { createContext, FC, useCallback, useState } from "react";
import useCallToService from "../../../hooks/useCallToService";
import { setQueryFunction } from "../../StandardContextUtils";

type AgentDailyReportQuery = {
  day: string;
  month: string;
  year: string;
};

type AgentDailyReportServiceContextProps = {
  id: string;
  query: AgentDailyReportQuery;
  collection: any[];
  setQuery: (field: string, value: any) => void;
  onFetch: (token?: string) => void;
  setId: (searchID: any) => void;
};

const defaultContext: AgentDailyReportServiceContextProps = {
  id: "",
  query: {
    day: "",
    month: "",
    year: "",
  },
  collection: [],
  setId: (searchID: any) => { },
  setQuery: (field: string, value: any) => { },
  onFetch: (token?: string) => { },
};
const getServiceEndpoint = (id: string, manualId?: string) =>
  `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/summary/${manualId || id}/agent/daily`;
export const AgentDailyReportServiceContext = createContext(defaultContext);
export const AgentDailyReportServiceContextProvider: FC<any> = ({ children }) => {
  const [collection, setCollection] = useState([]);
  const [id, setId] = useState(defaultContext.id);
  const [query, setLocalQuery] = useState(defaultContext.query);
  const [callToService] = useCallToService();

  const onFetch = useCallback(async (manualId?: string) => {
    try {
      const response = await callToService(getServiceEndpoint(id, manualId), "POST", query);
      if (response.data) setCollection(response.data);
    } catch (error) {
    }
  }, [callToService, query, id]);
  const setQuery = setQueryFunction(query, setLocalQuery);

  return (
    <AgentDailyReportServiceContext.Provider
      value={{
        id,
        query,
        collection,
        setQuery,
        onFetch,
        setId,
      }}>
      {children}
    </AgentDailyReportServiceContext.Provider>
  );
};
