import { createContext, FC, useCallback, useState } from "react";
import useCallToService from "../../../hooks/useCallToService";
import { IAgentSharing } from "../../../models/entities/AgentSharing";

interface IAgentSharingWithDate extends IAgentSharing {
  createdAt: Date;
  updatedAt: Date;
}

interface IAgentSharingEditServiceField extends Partial<IAgentSharing> { }

type TAgentSharingEditServiceProps = {
  id: string;
  data: IAgentSharingWithDate;
  fields: IAgentSharingEditServiceField;
  isProcessing: boolean;
  error: string;
  setId: Function;
  setFields: Function;
  saveFields: Function;
  fetch: Function;
};

const defaultContext: TAgentSharingEditServiceProps = {
  id: "",
  data: {
    agent: "",
    parentAgent: "",
    parentPercentage: 0,
    isDisable: false,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  fields: {
    agent: "",
    parentAgent: "",
    parentPercentage: 0,
  },
  isProcessing: false,
  error: "",
  setId: () => { },
  setFields: () => { },
  saveFields: () => { },
  fetch: () => { },
};
const createGetServiceEndpoint = (id: string, manualId?: string) =>
  `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/agent-sharing/get/${manualId || id
  }`;
const createUpdateServiceEndpoint = (id: string, manualId?: string) =>
  `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/agent-sharing/update/${manualId || id
  }`;
export const AgentSharingEditServiceContext = createContext(defaultContext);
export const AgentSharingEditServiceContextProvider: FC<any> = ({
  children,
}) => {
  const [id, setId] = useState(defaultContext.id);
  const [data, setData] = useState<IAgentSharingWithDate>(defaultContext.data);
  const [fields, setLocalFields] = useState(defaultContext.fields);
  const [isProcessing, setProcessing] = useState(false);
  const [error, setError] = useState("");
  const [callToService] = useCallToService();
  const fetch = useCallback(
    async (manualId?: string) => {
      setError("");
      setProcessing(true);
      try {
        const response = await callToService(
          createGetServiceEndpoint(id, manualId),
          "POST",
          {}
        );

        if (response.data) {
          setData(response.data);
          setLocalFields({
            agent: response.data.agent,
            parentAgent: response.data.parentAgent,
            parentPercentage: response.data.parentPercentage,
          });
        } else if (response.statusCode !== 1000) {
          setError(response.statusMessage);
        }
        setProcessing(false);
      } catch (error) {
        setError((error as Error).message);
        setProcessing(false);
      }
    },
    [callToService, id]
  );

  const saveFields = useCallback(
    async (callback: Function, manualId?: string) => {
      setProcessing(true);
      try {
        const response = await callToService(
          createUpdateServiceEndpoint(id, manualId),
          "PUT",
          fields
        );

        if (response.data) {
          setData(response.data);
          setLocalFields({
            agent: response.data.agent,
            parentAgent: response.data.parentAgent,
            parentPercentage: response.data.parentPercentage,
          });
          callback(response.data);
        } else if (response.statusCode !== 1000) {
          setError(response.statusMessage);
        }
        setProcessing(false);
      } catch (error) {
        setError((error as Error).message);
        setProcessing(false);
      }
    },
    [callToService, fields, id]
  );

  const setFields = (field: string, value: any) => {
    setLocalFields({ ...fields, [field]: value });
  };

  return (
    <AgentSharingEditServiceContext.Provider
      value={{
        id,
        data,
        fields,
        isProcessing,
        error,
        setId,
        setFields,
        saveFields,
        fetch,
      }}>
      {children}
    </AgentSharingEditServiceContext.Provider>
  );
};
