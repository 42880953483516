import { CursorArrowRaysIcon } from "@heroicons/react/24/outline";

const stats = [
  {
    id: 1,
    name: "Transaction",
    stat: "Detail",
    icon: CursorArrowRaysIcon,
    href: "/summary/tsdetail",
  },
  {
    id: 2,
    name: "Transaction",
    stat: "From Report",
    icon: CursorArrowRaysIcon,
    href: "/summary/tsreport",
  },
  {
    id: 3,
    name: "Agent",
    stat: "Daily",
    icon: CursorArrowRaysIcon,
    href: "/summary/agentdaily",
  },
  {
    id: 4,
    name: "Agent",
    stat: "Monthly",
    icon: CursorArrowRaysIcon,
    href: "/summary/agentmonthly",
  },
  {
    id: 5,
    name: "Game",
    stat: "Daily",
    icon: CursorArrowRaysIcon,
    href: "/summary/gamedaily",
  },
  {
    id: 6,
    name: "Game",
    stat: "Daily By Game",
    icon: CursorArrowRaysIcon,
    href: "/summary/gamedailybygame",
  },
  {
    id: 7,
    name: "Game",
    stat: "Monthly",
    icon: CursorArrowRaysIcon,
    href: "/summary/gamemonthly",
  },
  {
    id: 8,
    name: "Game",
    stat: "Monthly By Game",
    icon: CursorArrowRaysIcon,
    change: "3.2%",
    href: "/summary/gamemonthlybygame",
  },
];

export default function SummaryDashboard() {
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <dl className="mb-10 mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4 ">
        {stats.map((item) => (
          <div
            key={item.id}
            className="relative overflow-hidden rounded-lg bg-violet-400 px-4 pt-5 pb-1 shadow sm:px-6 sm:pt-6">
            <dt>
              <div className="absolute rounded-md bg-indigo-500 p-3">
                <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <a
                href={item.href}
                className="text-xl ml-16 truncate text-bold font-medium text-white hover:text-amber-100">
                {item.name}
              </a>
            </dt>
            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
              <a
                href={item.href}
                className="text-sm font-medium text-gray-500 hover:text-amber-100">
                {item.stat}
              </a>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}
