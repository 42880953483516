import { FC, useContext } from "react";
import CollectionFilter from "../components/collection/CollectionFilter";
import CollectionTable from "../components/collection/CollectionTable";
import NavigationPanel from "../components/NavigationPanel";
import {
  AgentDailyReportServiceContext,
  AgentDailyReportServiceContextProvider,
} from "../contexts/services/summary/AgentDailyReportServiceContext";
import SessionVerifier from "../contexts/SessionContext";
import SummaryDashboard from "../components/SummaryDashboard";

const SummaryAgentDailyScreen: FC<any> = () => {
  const contextDailyReport = useContext(AgentDailyReportServiceContext);

  return (
    <NavigationPanel>
      <div className="relative pt-6 mt-4">
        <CollectionFilter
          template={[
            {
              name: "Agent ID",
              field: "agentID",
              placeholder: "รหัส Agent",
              value: contextDailyReport.id,
              type: "textID",
            },
            {
              name: "วันที่",
              field: "day",
              placeholder: "วันเริ่มต้นที่ต้องการค้นหา",
              value: contextDailyReport.query.day,
              type: "text",
            },
            {
              name: "เดือน",
              field: "month",
              placeholder: "เดือนเริ่มต้นที่ต้องการค้นหา",
              value: contextDailyReport.query.month,
              type: "text",
            },
            {
              name: "ปี",
              field: "year",
              placeholder: "ปีที่ต้องการค้นหา",
              value: contextDailyReport.query.year,
              type: "text",
            },
          ]}
          onUpdateFilter={(field: any, value: any) =>
            contextDailyReport.setQuery(field, value)
          }
          onUpdateID={(searchID: any) => {
            contextDailyReport.setId(searchID);
          }}
          onSearch={() => contextDailyReport.onFetch()}
        />
        <SummaryDashboard />
        <CollectionTable
          texts={{
            title: "Agent Daily",
            description: "Agent Daily",
            buttonTextAdd: "สรุปผล",
            buttonTextEdit: "รายงานผลแต่ละ Agent",
          }}
          template={[
            { name: "วัน", field: "day" },
            { name: "เดือน", field: "month" },
            { name: "ปี", field: "year" },
          ]}
          collection={contextDailyReport.collection.map((item: any) => ({
            ...item,
          }))}
        />
      </div>
    </NavigationPanel>
  );
};

const SummaryAgentDailyScreenHoc = (props: any) => {
  return (
    <SessionVerifier>
      <AgentDailyReportServiceContextProvider>
        <SummaryAgentDailyScreen {...props} />
      </AgentDailyReportServiceContextProvider>
    </SessionVerifier>
  );
};
export default SummaryAgentDailyScreenHoc;
