import { FC } from "react";
import Modal from "react-modal";

type CrudModalProps = {
  error?: string;
  isModalOpen: boolean;
  onUpdateField?: Function;
  onToggleModal?: Function;
  onAfterModalClose?: Function;
  onSubmit?: Function;
};

const CrudModalDelete: FC<CrudModalProps> = ({
  error,
  isModalOpen,
  onUpdateField,
  onToggleModal,
  onAfterModalClose,
  onSubmit,
}) => {
  if (!isModalOpen) return <></>;

  return (
    <Modal
      isOpen={isModalOpen}
      onAfterOpen={() => {}}
      onRequestClose={() => onToggleModal && onToggleModal()}
      className="absolute bg-white p-8 max-sm:w-full sm:left-1/2 sm:-translate-x-1/2 rounded-md border-solid border-gray-300 border-2 overflow-auto flex flex-col"
      style={{
        content: {
          marginTop: 64,
          inset: undefined,
          maxHeight: "calc(80%)",
        },
      }}
      contentLabel="Example Modal"
    >
      <div className="flex flex-row flex-wrap overflow-auto">
        <div className="pr-2 mb-3 sm:w-auto w-full ">
          <label className="block text-sm font-medium text-gray-700 text-center">
            ยืนยันเปิด/ปิดใช้งาน
          </label>
        </div>
      </div>
      {error && (
        <div className="flex flex-row rounded-md justify-between px-4 text-white pt-2 pb-2 shadow-md bg-red-400">
          {error}
        </div>
      )}
      <div className="flex flex-row justify-center">
        <button
          type="button"
          className="items-center justify-center rounded-md border border-transparent bg-green-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto w-full mt-4"
          onClick={() => onSubmit && onSubmit()}
        >
          ยืนยันปิดใช้งานบัญชี
        </button>
      </div>
    </Modal>
  );
};

export default CrudModalDelete;
