import { FC, useContext } from "react";
import CollectionFilter from "../components/collection/CollectionFilter";
import CollectionTable from "../components/collection/CollectionTable";
import NavigationPanel from "../components/NavigationPanel";
import {
  GameMonthlyReportServiceContext,
  GameMonthlyReportServiceContextProvider,
} from "../contexts/services/summary/GameMonthlyReportServiceContext";
import SessionVerifier from "../contexts/SessionContext";
import SummaryDashboard from "../components/SummaryDashboard";

const SummaryGameMonthlyScreen: FC<any> = () => {
  const contextGameMonthlyReport = useContext(GameMonthlyReportServiceContext);

  return (
    <NavigationPanel>
      <div className="relative pt-6 mt-4">
        <CollectionFilter
          template={[
            {
              name: "รหัส Agent",
              field: "agentID",
              placeholder: "รหัส Agent",
              value: contextGameMonthlyReport.id,
              type: "textID",
            },
            {
              name: "ตั้งแต่วันที่",
              field: "month",
              placeholder: "วันเริ่มต้นที่ต้องการค้นหา",
              value: contextGameMonthlyReport.query.month,
              type: "datetime",
            },
            {
              name: "ถึงวันที่",
              field: "year",
              placeholder: "วันสิ้นสุดที่ต้องการค้นหา",
              value: contextGameMonthlyReport.query.year,
              type: "datetime",
            },
          ]}
          onUpdateFilter={(field: any, value: any) =>
            contextGameMonthlyReport.setQuery(field, value)
          }
          onUpdateID={(searchID: any) => {
            contextGameMonthlyReport.setId(searchID);
          }}
          onSearch={() => contextGameMonthlyReport.onFetch()}
        />
        <SummaryDashboard />
        <CollectionTable
          texts={{
            title: "Game Monthly",
            description: "Game Monthly",
            buttonTextEdit: "รายงานผลแต่ละ Agent",
          }}
          template={[
            { name: "เดือน", field: "month" },
            { name: "ปี", field: "year" },
          ]}
          collection={contextGameMonthlyReport.collection.map((item: any) => ({
            ...item,
          }))}
        />
      </div>
    </NavigationPanel>
  );
};

const SummaryGameMonthlyScreenHoc = (props: any) => {
  return (
    <SessionVerifier>
      <GameMonthlyReportServiceContextProvider>
        <SummaryGameMonthlyScreen {...props} />
      </GameMonthlyReportServiceContextProvider>
    </SessionVerifier>
  );
};
export default SummaryGameMonthlyScreenHoc;
