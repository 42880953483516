import { useCallback, useContext } from "react";
import background from "../assets/bg-1.jpg";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { Dna } from "react-loader-spinner";

const LandingScreen = () => {
  const authContext = useContext(AuthContext);
  const navigation = useNavigate();
  const onLogin = useCallback(async () => {
    if (authContext.onAuthenticate) {
      const result = await authContext.onAuthenticate();
      if (result) navigation("/dashboard");
    }
  }, [authContext, navigation]);

  return (
    <>
      <div className="h-full flex">
        <div className="flex-1">
          <img src={background} alt="" className="h-full" />
        </div>
        <div className="flex-1 bg-black">
          <div className="space-y-6 p-6">
            <h1 className="text-4xl font-bold text-rose-100">S.API System</h1>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-rose-100">
                Username
              </label>
              <div className="mt-1">
                <input
                  id="username"
                  name="username"
                  type="text"
                  required
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  value={authContext.username}
                  onChange={(e) => authContext.setUsername(e.target.value)}
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-rose-100">
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  value={authContext.password}
                  onChange={(e) => authContext.setPassword(e.target.value)}
                />
              </div>
              <div className="mt-6">
                {authContext.error && (
                  <div className="text-red-500 font-bold">
                    {authContext.error}
                  </div>
                )}
              </div>
            </div>

            <div className="flex items-center justify-between">
            </div>

            <div>
              {authContext.isProcessing ? (
                <div className="flex justify-center">
                  <Dna
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="dna-loading"
                    wrapperStyle={{}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
              ) : (
                <button
                  type="submit"
                  className="flex w-72 justify-center rounded-md border border-transparent bg-gradient-to-r from-cyan-500 to-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={onLogin}>
                  Login
                </button>
              )}{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingScreen;
