import { FC, useContext } from "react";
import CollectionFilter from "../components/collection/CollectionFilter";
import CollectionTable from "../components/collection/CollectionTable";
import NavigationPanel from "../components/NavigationPanel";
import {
  GameDailyReportByGameServiceContext,
  GameDailyReportByGameServiceContextProvider,
} from "../contexts/services/summary/GameDailyReportByGameServiceContext";
import SessionVerifier from "../contexts/SessionContext";
import SummaryDashboard from "../components/SummaryDashboard";

const SummaryGameDailyByGameScreen: FC<any> = () => {
  const contextDailyByGame = useContext(GameDailyReportByGameServiceContext);

  return (
    <NavigationPanel>
      <div className="relative pt-6 mt-4">
        <CollectionFilter
          template={[
            {
              name: "ตั้งแต่วันที่",
              field: "month",
              placeholder: "วันเริ่มต้นที่ต้องการค้นหา",
              value: contextDailyByGame.query.month,
              type: "datetime",
            },
            {
              name: "ถึงวันที่",
              field: "year",
              placeholder: "วันสิ้นสุดที่ต้องการค้นหา",
              value: contextDailyByGame.query.year,
              type: "datetime",
            },
          ]}
          onUpdateFilter={(field: any, value: any) =>
            contextDailyByGame.setQuery(field, value)
          }
          onSearch={() => contextDailyByGame.onFetch()}
        />
        <SummaryDashboard />
        <CollectionTable
          texts={{
            title: "Game Daily By Game",
            description: "Game Daily By Game",
            buttonTextAdd: "สรุปผล",
            buttonTextEdit: "รายงานผลแต่ละ Agent",
          }}
          template={[
            { name: "วัน", field: "day" },
            { name: "เดือน", field: "month" },
            { name: "ปี", field: "year" },
          ]}
          collection={contextDailyByGame.collection.map((item: any) => ({
            ...item,
          }))}
        />
      </div>
    </NavigationPanel>
  );
};

const SummaryGameDailyByGameScreenHoc = (props: any) => {
  return (
    <SessionVerifier>
      <GameDailyReportByGameServiceContextProvider>
        <SummaryGameDailyByGameScreen {...props} />
      </GameDailyReportByGameServiceContextProvider>
    </SessionVerifier>
  );
};
export default SummaryGameDailyByGameScreenHoc;
