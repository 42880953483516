import { createContext, FC, useCallback, useState } from "react";
import useCallToService from "../../../hooks/useCallToService";
import { setQueryFunction } from "../../StandardContextUtils";

type AgentListQuery = {
  username: string;
  prefix: string;
  startDate: string;
  endDate: string;
  page: number;
  limit: number;
};

type AgentListServiceContextProps = {
  query: AgentListQuery;
  collection: any[];
  setQuery: (field: string, value: any) => void;
  onFetch: (token?: string) => void;
};

const defaultContext: AgentListServiceContextProps = {
  query: {
    username: "",
    prefix: "",
    startDate: "",
    endDate: "",
    page: 1,
    limit: 50,
  },
  collection: [],
  setQuery: (field: string, value: any) => { },
  onFetch: (token?: string) => { },
};
const getServiceEndpoint = () =>
  `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/agent/list`;
export const AgentListServiceContext = createContext(defaultContext);
export const AgentListServiceContextProvider: FC<any> = ({ children }) => {
  const [collection, setCollection] = useState([]);
  const [query, setLocalQuery] = useState(defaultContext.query);
  const [callToService] = useCallToService();

  const onFetch = useCallback(async () => {
    try {
      const response = await callToService(getServiceEndpoint(), "POST", query);
      if (response.data) setCollection(response.data);
    } catch (error) {
    }
  }, [callToService, query]);
  const setQuery = setQueryFunction(query, setLocalQuery);

  return (
    <AgentListServiceContext.Provider
      value={{
        query,
        collection,
        setQuery,
        onFetch,
      }}>
      {children}
    </AgentListServiceContext.Provider>
  );
};
