import { FC, useContext } from "react";
import {
  AdminCreateServiceContext,
  AdminCreateServiceContextProvider,
} from "../../contexts/services/admin/AdminCreateServiceContext";
import CrudModal from "../CrudModal";

type AdminCreateModalProps = {
  mode: string;
  isOpen: boolean;
  setOpen: Function;
  afterSave?: Function;
};
const AdminCreateModal: FC<AdminCreateModalProps> = ({
  mode,
  isOpen,
  setOpen,
  afterSave,
}) => {
  const contextAdmin = useContext(AdminCreateServiceContext);

  return (
    <CrudModal
      template={[
        {
          name: "ชื่อผู้ใช้",
          field: "username",
          placeholder: "ชื่อผู้ใช้",
          value: contextAdmin.fields.username,
          type: "text",
        },
        {
          name: "รหัสผ่าน",
          field: "password",
          placeholder: "รหัสผ่าน",
          value: contextAdmin.fields.password,
          type: "password",
        },
        {
          name: "ประเภท",
          field: "type",
          placeholder: "ประเภท",
          value: contextAdmin.fields.type,
          type: "roleAdmin",
        },
      ]}
      error={contextAdmin.error}
      isModalOpen={isOpen}
      onToggleModal={() => setOpen(!isOpen)}
      onUpdateField={(field: string, value: any) => {
        contextAdmin.setFields(field, value);
      }}
      onSubmit={
        mode === "info" ? () => {
          contextAdmin.saveFields(() => {
            setOpen(false);
            afterSave && afterSave();
          });
        } : undefined
      }
    />
  );
};

const AdminCreateModalHoc = (props: AdminCreateModalProps) => {
  return (
    <AdminCreateServiceContextProvider>
      <AdminCreateModal {...props} />
    </AdminCreateServiceContextProvider>
  );
};
export default AdminCreateModalHoc;
