import { FC, useCallback, useContext, useEffect, useState, useRef } from "react";
import CollectionFilter from "../components/collection/CollectionFilter";
import CollectionTable from "../components/collection/CollectionTable";
import AgentOperatorCreateModalHoc from "../components/modal/AgentOperatorCreateModal";
import NavigationPanel from "../components/NavigationPanel";
import {
  AgentOperatorListServiceContext,
  AgentOperatorListServiceContextProvider,
} from "../contexts/services/agentOperator/AgentOperatorListServiceContext";
import SessionVerifier from "../contexts/SessionContext";

const AgentOperatorScreen: FC<any> = () => {
  const contextAgentsOperator = useContext(AgentOperatorListServiceContext);
  const [isAgentOperatorCreateModalOpen, setAgentOperatorCreateModalOpen] = useState(false);

  const fetchCollection = useCallback(() => {
    contextAgentsOperator.onFetch();
  }, [contextAgentsOperator]);

  const shouldFetch = useRef(true);

  useEffect(() => {
    if (shouldFetch.current) {
      shouldFetch.current = false;
      fetchCollection();
    }
  });

  return (
    <NavigationPanel>
      <div className="relative pt-6 mt-4">
        <CollectionFilter
          template={[
            {
              name: "Agent",
              field: "agent",
              placeholder: "Agent",
              value: contextAgentsOperator.query.agent,
              type: "text",
            },

          ]}
          onUpdateFilter={(field: any, value: any) =>
            contextAgentsOperator.setQuery(field, value)
          }
          onSearch={() => contextAgentsOperator.onFetch()}
        />
        <CollectionTable
          texts={{
            title: "Agent Operator",
            description: "รายชื่อเอเย่นท์",
            buttonTextAdd: "ADD",
            buttonTextEdit: "แก้ไข",
            buttonTextDelete: "ลบ",
          }}
          template={[
            {
              name: "Agent",
              field: "agent",
              isEvent: true,
            },
            {
              name: "Username",
              field: "username",
            },
          ]}
          collection={contextAgentsOperator.collection.map((item: any) => ({
            ...item,
          }))}
          events={{
            onAdd: (data: { codename: string }) => {
              setAgentOperatorCreateModalOpen(true);
            },
          }}
        />
        <AgentOperatorCreateModalHoc
          isOpen={isAgentOperatorCreateModalOpen}
          setOpen={(status: boolean) => setAgentOperatorCreateModalOpen(status)}
          afterSave={() => { fetchCollection() }}
          mode={"info"}
        />
        
      </div>
    </NavigationPanel>
  );
};

const AgentOperatorScreenHoc = (props: any) => {
  return (
    <SessionVerifier>
      <AgentOperatorListServiceContextProvider>
        <AgentOperatorScreen {...props} />
      </AgentOperatorListServiceContextProvider>
    </SessionVerifier>
  );
};
export default AgentOperatorScreenHoc;
