import { FC, useCallback, useContext, useEffect, useState, useRef } from "react";
import CollectionFilter from "../components/collection/CollectionFilter";
import CollectionTable from "../components/collection/CollectionTable";
import AgentSharingCreateModalHoc from "../components/modal/AgentSharingCreateModal";
import AgentSharingEditModalHoc from "../components/modal/AgentSharingEditModal";
import NavigationPanel from "../components/NavigationPanel";
// import { AgentListServiceContext, AgentListServiceContextProvider } from "../contexts/services/agent/AgentListServiceContext";
import {
  AgentSharingListServiceContext,
  AgentSharingListServiceContextProvider,
} from "../contexts/services/agentSharing/AgentSharingListServiceContext";
import { AgentSharingDeleteServiceContextProvider } from "../contexts/services/agentSharing/AgentSharingDeleteServiceContext";
import SessionVerifier from "../contexts/SessionContext";
import AgentSharingDeleteModalHoc from "../components/modal/AgentSharingDeleteModal";

const AgentSharingScreen: FC<any> = () => {
  const contextAgentSharing = useContext(AgentSharingListServiceContext);
  const [isAgentSharingCreateModalOpen, setAgentSharingCreateModalOpen] = useState(false);

  const [editId, setEditId] = useState("");
  const [isSharingEditModalOpen, setSharingEditModalOpen] = useState(false);

  const [delId, setdelId] = useState("");
  const [isAgentSharingDeleteModalOpen, setAgentSharingDeleteModalOpen] = useState(false);

  const fetchCollection = useCallback(() => {
    contextAgentSharing.onFetch();
  }, [contextAgentSharing]);

  const shouldFetch = useRef(true);

  useEffect(() => {
    if (shouldFetch.current) {
      shouldFetch.current = false;
      fetchCollection();
    }
  });

  return (
    <NavigationPanel>
      <div className="relative pt-6 mt-4">
        <CollectionFilter
          template={[
            {
              name: "Agent",
              field: "agent",
              placeholder: "Agent",
              value: contextAgentSharing.query.agent,
              type: "text",
            },
            {
              name: "Parent Agent",
              field: "parentAgent",
              placeholder: "Parent Agent",
              value: contextAgentSharing.query.parentAgent,
              type: "text",
            },
          ]}
          onUpdateFilter={(field: any, value: any) =>
            contextAgentSharing.setQuery(field, value)
          }
          onSearch={() => contextAgentSharing.onFetch()}
        />
        <CollectionTable
          texts={{
            title: "Agent Sharing",
            description: "รายชื่อเอเย่นท์",
            buttonTextAdd: "ADD",
            buttonTextEdit: "แก้ไข",
            buttonTextDelete: "ปิดใช้งาน",
          }}
          template={[
            {
              name: "Agent",
              field: "agent",
              isEvent: true,
              eventCallback: (data: { _id: string }) => {
                setEditId(data._id);
                setSharingEditModalOpen(true);
              },
            },

            {
              name: "Parent Agent",
              field: "parentAgent.prefix",
            },
            {
              name: "Parent Percentage",
              field: "parentPercentage",
            },
            {
              name: "บัญชีถูกระงับ",
              type: "boolean",
              field: "isDisable",
              isEvent: true,
              eventCallback: (data: { _id: string }) => {
                setdelId(data._id);
                setAgentSharingDeleteModalOpen(true);
              },
            }
          ]}
          collection={contextAgentSharing.collection.map && contextAgentSharing.collection.map((item: any,) => ({
            ...item,
          }))}
          events={{
            onAdd: (data: { codename: string }) => {
              setAgentSharingCreateModalOpen(true);
            },
            onEdit: (data: { _id: string }) => {
              setEditId(data._id);
              setSharingEditModalOpen(true);
            },
          }}
        />
        <AgentSharingDeleteModalHoc
          id={delId}
          isOpen={isAgentSharingDeleteModalOpen}
          setOpen={(status: boolean) => setAgentSharingDeleteModalOpen(status)}
          afterSave={() => { fetchCollection() }}
          mode={"info"}
        />
        <AgentSharingCreateModalHoc
          isOpen={isAgentSharingCreateModalOpen}
          setOpen={(status: boolean) => setAgentSharingCreateModalOpen(status)}
          afterSave={() => { fetchCollection() }}
          mode={"info"}
        />
        <AgentSharingEditModalHoc
          id={editId}
          isOpen={isSharingEditModalOpen}
          setOpen={(status: boolean) => setSharingEditModalOpen(status)}
          afterSave={() => { fetchCollection() }}
          mode={"info"}
        />
      </div>
    </NavigationPanel>
  );
};

const AgentSharingScreenHoc = (props: any) => {
  return (
    <SessionVerifier>
      <AgentSharingDeleteServiceContextProvider>
        <AgentSharingListServiceContextProvider>
          <AgentSharingScreen {...props} />
        </AgentSharingListServiceContextProvider>
      </AgentSharingDeleteServiceContextProvider>
    </SessionVerifier>
  );
};
export default AgentSharingScreenHoc;
