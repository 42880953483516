import axios from "axios";
import { createContext, FC, useCallback, useState } from "react";

interface IAuthContext {
  username: string;
  password: string;
  token: string;
  error: string;
  isProcessing: boolean;
  setUsername: (username: string) => void;
  setPassword: (password: string) => void;
  onAuthenticate: null | (() => Promise<boolean>);
  onReset: () => void;
  onCheckToken: () => Promise<boolean>;
}
const authContextDefault: IAuthContext = {
  username: "",
  password: "",
  token: "",
  error: "",
  isProcessing: false,
  setUsername: (username: string) => {},
  setPassword: (password: string) => {},
  onAuthenticate: null,
  onReset: () => {},
  onCheckToken: () => Promise.resolve(false),
};
export const AuthContext = createContext(authContextDefault);
export const AuthContextProvider: FC<any> = ({ children }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [error, setError] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const onAuthenticate = useCallback(async () => {
    setIsProcessing(true);
    setError("");
    try {
      const request = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/admin/authorize`,
        {
          username,
          password,
        }
      );

      if (request.data.error) {
        setError(`ไม่สามารถเข้าระบบได้: ${request.data.error}`);
        setIsProcessing(false);
        return false;
      }

      localStorage.setItem("F888PARTNER:token", request.data.data.token);
      setToken(request.data.data.token);
      setError("");
      setIsProcessing(false);
      return true;
    } catch (error) {
      setError("ไม่สามารถเข้าระบบได้");
      setIsProcessing(false);
      return false;
    }
  }, [username, password]);

  const onReset = useCallback(async () => {
    setToken("");
    setUsername("");
    setPassword("");
    setError("");
    setIsProcessing(false);
  }, []);

  const onCheckToken = useCallback(async () => {
    const savedToken = localStorage.getItem("F888PARTNER:token");
    if (savedToken) {
      setToken(savedToken);
      const savedMode = localStorage.getItem("F888PARTNER:mode");
      try {
        const verifiedToken = await axios.post(
          `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/${savedMode}/self`,
          null,
          {
            headers: {
              Authorization: `Bearer ${savedToken}`,
            },
          }
        );
        const verified = verifiedToken.data.data;
        setIsProcessing(false);
        setUsername(verified.username);
        return true;
      } catch (error) {
        return false;
      }
    } else {
      return false;
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        username,
        password,
        token,
        error,
        isProcessing,
        setUsername: (value) => setUsername(value),
        setPassword: (value) => setPassword(value),
        onAuthenticate,
        onReset,
        onCheckToken,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
